import {
  Box,
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

import axios from "../axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
import "../styles/AllMainPagesCss.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
const url = process.env.REACT_APP_BE_URL;

const SubTitleHeaders = {
  fontWeight: "700",
  fontSize: "1.2rem",
  marginRight: "1rem",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  //textOverflow: "ellipsis"
};

// eslint-disable-next-line
const data = {
  projectID: "",
};


const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState(null);
  const [callback, setCallback] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(10);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, [pageNumber]);

  const getProjects = async () => {
    setCallback(false)
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    const { data } = await axios.get(`${url}/project?page_number=${pageNumber}`, config);
    setProjects(data);
    setCallback(true)
  };

  const getTotalPageCount = async () => {
    setCallback(false)
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
      },
    };
    const { data } = await axios.get(`${url}/project/pageCount`, config)
    setCount(data)
    setCallback(true)
  };

  useEffect(() => {
    getTotalPageCount();
  }, []);

  // Project Form States
  const modalFunc = () => {
    navigate("/createproject1");
  };

  return (
    <>
      <Navbar />
      <Grid container lg={12}>
        <Grid container item lg={1} md={1.5} sm={1.8} xs={1}>
          <Sidebar />
        </Grid>

        <Grid
          container
          item
          marginTop={10}
          marginBottom={5}
          lg={10.5}
          md={9.8}
          sm={10}
          xs={10}
        >
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            justifyContent={{
              lg: "flex-end",
              md: "flex-end",
              sm: "flex-end",
              xs: "center",
            }}
            marginBottom={{
              lg: "1rem",
              md: "1rem",
              sm: "1rem",
              xs: "1rem",
            }}
            marginTop={{
              lg: "-1rem",
              md: "-0.5rem",
              sm: "-0.5rem",
              xs: "-0.5rem",
            }}
          >
            <Button
              color="secondary"
              onClick={() => navigate("/approvalRequest")}
              variant="contained" sx={{
                borderRadius: "8px",
                textTransform: "none",
                marginLeft: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "0.5rem",
                },
                marginRight: {
                  lg: "0rem",
                  md: "0rem",
                  sm: "0rem",
                  xs: "0.5rem",
                },
                marginTop: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "1rem",
                },
                marginBottom: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "1rem",
                },
              }}>
              Approval Requests
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                marginLeft: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "0.5rem",
                },
                marginRight: {
                  lg: "0rem",
                  md: "0rem",
                  sm: "0rem",
                  xs: "0.5rem",
                },
                marginTop: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "1rem",
                },
                marginBottom: {
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "1rem",
                },
              }}
              onClick={modalFunc}
            >
              Add a Project
            </Button>
          </Grid>

          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container item >

            {projects && callback ? (
              projects.length > 0 ? (
                projects.map((project) => {
                  return (
                    <Grid container
                      key={project.name}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                    >

                      <Box sx={{
                        cursor: "pointer",
                        width: "98%",
                        height: "96%"
                      }}

                        border={1}
                        borderRadius="8px"
                        padding={2}
                        onClick={() => navigate(`/projectdetails/${project._id}`)}>

                        <Grid container >
                          <Grid item container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Typography color="primary"
                                sx={SubTitleHeaders} alignItems="center">
                                <ListAltRoundedIcon fontSize="medium" sx={{ marginRight: "0.3rem" }} />
                                {project.name}
                              </Typography>
                            </Grid>
                            <Grid item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Typography
                                style={{
                                  fontSize: "0.75rem",
                                  lineHeight: "1.3rem",
                                  paddingTop: "-3px",
                                  fontWeight: "bold",
                                  marginTop: "-5px",
                                }}
                              >
                                {project.industry}
                              </Typography>
                            </Grid>

                            <Grid item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <List dense disablePadding disableGutters>
                                <ListItem
                                  disablePadding
                                  disableGutters
                                  sx={{ display: "list-item" }}
                                >
                                  <Typography
                                    sx={{ fontSize: "0.75rem", lineHeight: "1.3rem", height: "5.5rem" }}
                                    className="projectPara"
                                  >
                                    {project.description}
                                  </Typography>
                                </ListItem>
                              </List>
                            </Grid>

                            <Grid item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Chip
                                sx={{
                                  fontSize: "0.7rem",
                                  marginRight: "1rem",
                                  padding: "15px 10px 15px 10px",
                                  marginTop: "20px",
                                  marginBottom: "0.5rem",
                                }}
                                size="small"
                                label={project.status}
                                color={
                                  project.status === "pending"
                                    ? "warning"
                                    : "success"
                                }
                                icon={
                                  project.status === "pending" ? (
                                    <PriorityHighIcon />
                                  ) : (
                                    <CheckIcon />
                                  )
                                }
                              />
                            </Grid>

                          </Grid>

                        </Grid>
                      </Box>
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: "60vh" }}
                >
                  <Grid item>
                    <Typography variant="h4" fontWeight="900">
                      You have no projects
                    </Typography>
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                style={{ height: "60vh" }}
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
          </Grid>
          {count > 1 ?
            <Grid xs={12}
              sm={12}
              md={12}
              lg={12} container justifyContent={"center"}/*  sx={{ marginTop: { xs: 1 } }} */>
              {callback ?
                <Stack spacing={2}>
                  <Pagination
                    count={count}
                    page={pageNumber}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    style={{
                      transform: "scale(0.8)",
                    }}
                  />
                </Stack> : <p>Loading...</p>}
            </Grid> : null}
        </Grid>
      </Grid >
    </>
  );
};

export default Projects;
