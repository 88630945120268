import React, { Suspense } from "react";
import "./App.css";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import Profile from "./Pages/Profile";
import ResetPassword from "./Pages/ResetPasssword";
import SimpleNavBar from "./Components/SimpleNavBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NotFound from "./Components/NotFound";
import PermissionDenied from "./Components/PermissionDenied";
import { routes } from "./routes";
import ProtectedRoute from "./common/ProtectedRoute";
import Auth from "./common/Auth";
import Loader from "./utils/loader";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store/store";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0066B2",
    },
    secondary: {
      main: "#009E60",
    },
  },
  typography: {
    fontFamily: ["Manrope", "sans-serif"].join(","),
    fontSize: 14,
  },
});

function App() {
  const id = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Auth>
            <BrowserRouter>
              <Routes>
                {id ? (
                  <Route path={`/profile/:userId`} element={<Profile />} />
                ) : (
                  <Route path="/" element={<SignIn />} />
                )}
                <Route path="/simplenavbar" element={<SimpleNavBar />} />
                <Route path="/signup" element={<SignUp />} />

                <Route path="/signin" element={<SignIn />} />
                <Route path="/" element={<SignIn />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="*" element={<NotFound />} />
                <Route
                  path="/permission-denied"
                  exact
                  element={<PermissionDenied />}
                />
                {routes.map(({ element, path, name }) => (
                  <Route
                    key={name}
                    path={path}
                    element={<ProtectedRoute element={element} />}
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </Auth>
        </Suspense>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
