import { CLEAR_PROJECT_FORM_DATA, GET_PROJECT_FORM_1_DATA, } from "./types"

export const getForm1Data = (data) => {
    return {
        type: GET_PROJECT_FORM_1_DATA,
        payload: data
    }
}

export const clearFormData = () => {
    return {
        type: CLEAR_PROJECT_FORM_DATA,
    }
}