import React, { useEffect, useState } from "react";
import {
    Grid,
    Modal,
    Box,
    Snackbar,
    Alert,
    Typography,
    Checkbox,
    List,
    ListItem,
    Button
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import axiosInstance from "../../axios";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { useNavigate, useParams, } from "react-router-dom";
import { Formik, Form } from "formik";



const url = process.env.REACT_APP_BE_URL;

const TitleStyles = {
    backgroundColor: "#fff",
    color: "#000",
    fontWeight: "800",
    textAlign: "center"
};


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
        lg: 600,
        md: 540,
        sm: 540,
        xs: "89%",
    },
    bgcolor: "#fff",
    boxShadow: 30,
    borderRadius: "8px",
    padding: "10px 10px 30px 10px",
    border: "0px",
};

function JobAgreementModal() {
    const navigate = useNavigate();
    const [job, setJob] = useState(null);
    const [checkBox, setCheckBox] = useState(false);
    const { applicationId } = useParams();

    const [alert, setAlert] = useState({
        showAlert: false,
        severity: "success",
        message: "",
    });

    function handleClose() {
        setAlert({
            ...alert,
            showAlert: false,
            severity: "",
            message: "",
        });
    }

    const userId = JSON.parse(localStorage.getItem("userInfo"))._id


    const getJob = async () => {

        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
                    }`,
            },
        };
        try {


            const { data, status } = await axiosInstance.get(
                `${url}/application/jobAgreement/${applicationId}`,
                config)
            if (status === 200) {
                setJob(data)
            }

        } catch (error) {
            setAlert({
                showAlert: true,
                severity: "error",
                message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
            });

            setTimeout(() => {
                setAlert({
                    showAlert: false,
                    severity: "success",
                    message: "",
                });
            }, 16000);
        }

    };

    useEffect(() => {
        getJob()
    }, [])




    const submit = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
            },
        };

        try {
            let payload = {
                status: 'accepted'
            }
            let { data, status } = await axiosInstance.put(`${url}/application/accept/${applicationId}`, payload, config);
            if (status === 200) {
                setAlert({
                    showAlert: true,
                    severity: "success",
                    message: `${data?.message ? data?.message : data ? data : "You accepted the job successfully!"}`
                });

                setTimeout(() => {
                    setAlert({
                        showAlert: false,
                        severity: "success",
                        message: "",
                    });
                }, 16000);

                navigate(`/profile/${userId}`)

            }
        } catch (error) {
            setAlert({
                showAlert: true,
                severity: "error",
                message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
            });

            setTimeout(() => {
                setAlert({
                    showAlert: false,
                    severity: "success",
                    message: "",
                });
            }, 16000);
        }

    }


    const handleChange = (event) => {
        setCheckBox(event.target.checked)
    }
    let initialValues3 = {
    };


    return (
        <>
            <Navbar />

            <Grid container>

                <Grid container item
                    lg={1}
                    md={1.5}
                    sm={1.8}
                    xs={1}
                >
                    <Sidebar />
                </Grid>

                <Snackbar
                    sx={{ mt: "8vh" }}
                    open={alert.showAlert}
                    autoHideDuration={15000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={alert.severity}
                        sx={{
                            fontSize: "16px",
                        }}
                    >
                        {alert.message}
                    </Alert>
                </Snackbar>

                {job &&

                    <Modal
                        open={true}
                    >
                        <Box sx={style}>
                            <Grid container justifyContent="center">
                                <Grid item
                                    lg={11}
                                    md={11}
                                    sm={11}
                                    xs={11}
                                >
                                    <Typography
                                        style={TitleStyles}
                                        sx={{
                                            fontSize: {
                                                lg: "2rem",
                                                md: "2rem",
                                                sm: "1.5rem",
                                                xs: "1.4rem",
                                            },
                                            marginTop: {
                                                lg: "2rem",
                                                md: "2rem",
                                                sm: "2rem",
                                                xs: "1rem",
                                            },
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        Job Agreement

                                    </Typography>

                                    <Grid
                                        paragraph
                                        sx={{
                                            marginTop: "1rem",
                                            wordBreak: "break-word",
                                            height: {
                                                lg: "18rem",
                                                md: "18rem",
                                                sm: "18rem",
                                                xs: "18rem",
                                            },
                                            overflow: "auto",
                                            borderStyle: "solid",
                                            borderWidth: "0.1rem",
                                            borderColor: "#c1c1c1",
                                            borderRadius: "8px",
                                            padding: "1rem",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ wordWrap: "break-word" }} >
                                            <Grid container item justifyContent="flex-start">
                                                <Grid item xs={4.5} md={4}>
                                                    <List dense disablePadding disableGutters>
                                                        <ListItem
                                                            disablePadding
                                                            disableGutters
                                                            sx={{ display: "list-item" }}

                                                        >
                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                                Job Name
                                                            </Typography>
                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }} >
                                                                Job Type
                                                            </Typography>
                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }} >
                                                                Units
                                                            </Typography>
                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                                Time Availability
                                                            </Typography>
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={7.5}
                                                    md={8}
                                                    sx={{ wordWrap: "break-word" }}
                                                >
                                                    <List dense disablePadding disableGutters>
                                                        <ListItem
                                                            disablePadding
                                                            sx={{ display: "list-item" }}
                                                        >
                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                                -  {job?.title}
                                                            </Typography>

                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                                -  {job?.jobType}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                                -  {job?.units}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                                -  {job?.timeAvailability}
                                                            </Typography>
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                            </Grid>
                                            <Typography mt="1rem" overflow="auto" sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                {job?.description}
                                            </Typography>
                                        </Grid>



                                    </Grid>

                                </Grid>

                                <Grid container item
                                    xs={12}
                                    direction="column" alignItems="center" mt={2}>

                                    <Formik initialValues={initialValues3} onSubmit={submit}>
                                        <Form>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={checkBox} onChange={handleChange} />}
                                                    label={<Typography sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}>
                                                        I accept the above job
                                                    </Typography>} />
                                            </Grid>



                                            <Grid item>
                                                <Button
                                                    sx={{
                                                        borderRadius: "8px",
                                                        fontSize: { xs: "0.75rem", sm: "1rem" },
                                                        fontWeight: "700",
                                                        height: "2.5rem",
                                                        marginTop: "0.5rem",
                                                    }}
                                                    color="primary"
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={!checkBox}
                                                    type="submit"
                                                    size="large"
                                                >
                                                    Confirm
                                                </Button >
                                            </Grid >
                                        </Form>
                                    </Formik>
                                </Grid >

                            </Grid >
                        </Box >
                    </Modal >
                }
            </Grid >

        </>
    );
}

export default JobAgreementModal;
