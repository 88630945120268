import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  Snackbar,
  Alert,
  Typography,
  Checkbox,
} from "@mui/material";

import FormControlLabel from '@mui/material/FormControlLabel';
import axiosInstance from "../../../axios";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useParams } from "react-router-dom";

const url = process.env.REACT_APP_BE_URL;

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
};

const fieldStyles = {
  width: {
    lg: 570,
    md: 430,
    sm: 398,
    xs: "100%",
  },
  marginBottom: "10px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    lg: 700,
    md: 540,
    sm: 540,
    xs: "89%",
  },
  bgcolor: "#fff",
  boxShadow: 30,
  borderRadius: "8px",
  padding: "10px 10px 30px 10px",
  border: "0px",
};


function NdaAcknowledgement() {

  var today = new Date();
  const [initialValues, setInitialValues] = useState({
    name: "",
    date: today,
    tick: ""
  });

  const { ndaId } = useParams();

  //after confirm click
  const [afterEmailsent, setAfterEmailsent] = useState(false);

  //validate and set name

  const [nameError, setNameError] = useState(false);

  const setName = (nameValue) => {


    setInitialValues(oldValue => ({
      ...oldValue,
      name: nameValue.target.value,
    })
    )

    if (nameValue.target.value.length === 0) {
      setNameError(true)

    } else {
      setNameError(false)
    }

  }

  //validate and set date

  const [dateError, setDateError] = useState(false);

  const dateSet = (dateValue) => {
    setInitialValues(oldValue => ({
      ...oldValue,
      date: dateValue,
    })
    )

    //date validation

    if (dateValue.setHours(0, 0, 0, 0) !== today.setHours(0, 0, 0, 0)) {

      setDateError(true)


    } else {
      setDateError(false)
    }

  }

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }


  const updateNdaStatus = async () => {

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {

      let ndaPayLoad = {
        status: "ReadyForAcknowledgement",
        name: initialValues.name,
        date: initialValues.date
      };

      const res = await axiosInstance.put(
        `${url}/nda/${ndaId}`,
        ndaPayLoad,
        config
      );

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Success"}`,
        });
        setAfterEmailsent({

          status: true,

          message: `${res.data?.message ?
            res.data.message
            : "Success"
            }`
        })

      }

    } catch (error) {
      afterEmailsent({

        status: false,

        message: `${error.response.data ? error.response.data : error.message}`
      });
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  return (
    <>

      <Navbar />

      <Grid container>

        <Grid container item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={1}
        >
          <Sidebar />
        </Grid>


        <Snackbar
          sx={{ mt: "8vh" }}
          open={alert.showAlert}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={alert.severity}
            sx={{
              fontSize: "16px",
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>

        <Modal
          open={true}
        // onClose={false}
        >
          <Box sx={style}>
            <Grid container justifyContent="center">
              <Grid item
                lg={10}
                md={10}
                sm={10}
                xs={10}
              >
                <Typography
                  style={TitleStyles}

                  sx={{
                    fontSize: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "1.5rem",
                      xs: "1.4rem",
                    },
                    marginTop: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "2rem",
                      xs: "1rem",
                    },

                    textAlign: afterEmailsent.status ? "center" : "left"
                  }}
                >
                  {afterEmailsent.status ? "Email sent" : "NDA Acknowledgement"}
                </Typography>

                <Typography
                  paragraph
                  sx={{
                    marginTop: "1rem",
                    wordBreak: "break-word",
                    fontSize: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "0.9rem",
                      xs: "0.89rem",
                    },
                    height: {
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "15rem",
                    },
                    overflowY: "auto",
                    textAlign: afterEmailsent.status ? "center" : "left"
                  }}
                >
                  {afterEmailsent.status ?

                    `${afterEmailsent.message}`

                    : `You consent and agree that your use of a keypad, touch screen,
                  mouse or other device to sign or select an item, button,
                  icon or similar act/action while using any electronic service we offer;
                  or in accessing or making any transactions regarding any document, agreement,
                  acknowledgement, consent, term, disclosure, or condition constitutes your signature,
                  acceptance and agreement as if actually signed by you in writing. Further,
                  you agree that no certification authority or other third party verification is necessary to
                  validate your electronic signature; and that the lack of such certification or third party
                  verification will not affect the enforceability of your signature or resulting agreement
                  between you and the TinT.`}

                </Typography>

              </Grid>

              {!afterEmailsent.status &&
                <Grid container item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  direction="column" alignItems="center">

                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox

                        value={initialValues.tick}
                        onClick={(tickValue) => {
                          setInitialValues(oldValue => ({
                            ...oldValue,
                            tick: tickValue.target.checked,
                          })
                          )

                        }}

                      />}
                      label="I agree to all the conditions and agreements"
                      sx={{
                        marginBottom: "1rem",
                        wordBreak: "break-word",
                      }}
                    />
                  </Grid>

                  <Grid item>

                    <TextField
                      error={nameError}
                      helperText={nameError && "Name must not be empty"}
                      required
                      sx={fieldStyles}
                      name="name"
                      label="Name"
                      variant="outlined"
                      type="text"
                      value={initialValues.name}
                      onChange={(nameValue) => setName(nameValue)}
                      size="small"
                      margin="dense">
                    </TextField>
                  </Grid>

                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        error={dateError}
                        label="Date"
                        inputFormat="MM/dd/yyyy"
                        name="date"
                        value={initialValues.date}
                        onChange={(e) => dateSet(e)}
                        renderInput={(params) =>
                          <TextField
                            helperText={dateError && "Date must be current date"}
                            required
                            name="date"
                            sx={{
                              width: {
                                lg: 570,
                                md: 430,
                                sm: 430,
                                xs: "100%",
                              },
                            }}
                            {...params}

                            error={dateError}
                          />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item>
                    <Button
                      sx={{
                        borderRadius: "8px",
                        fontSize: "1rem",
                        fontWeight: "700",
                        height: "2.5rem",
                        marginTop: "1rem",
                        textTransform: "capitalize"
                      }}
                      color="primary"
                      fullWidth
                      variant="contained"
                      disabled={!initialValues.tick || !initialValues.name || !initialValues.date}
                      type="submit"
                      size="large"
                      onClick={updateNdaStatus}
                    >
                      Confirm
                    </Button>
                  </Grid>

                </Grid>}

            </Grid>
          </Box>
        </Modal>

      </Grid>

    </>
  );
}

export default NdaAcknowledgement;
