export const chartColors = [
    "#336699",
    "#E0FBFC",
    "#C2DFE3",
    "#9DB4C0",
    "#DBA8AC",
    "#60E1E0",
    "#D8D2E1",
    "#B88E8D",
    "#594236",
    "#CDD7D6",
    "#CDD7D6",
    "#B3A394",
    "#FFFFFF",
    "#E7EFC5",
    "#F2DDA4",
    "#ADA8B6",
    "#83E8BA",
    "#B2E6D4",
    "#A49E8D",
    "#EC9A29",
    "#A8201A",
    "#170312",
    "#0CCE6B",
    "#ED7D3A",
    "#DCED31",
    "#EBF8B8",
    "#AAA694",
    "#61210F",
    "#CACAAA",
    "#CCCC33",
    "#EEC584",
    "#E0B7B7",
    "#94BFA7",
    "#17BEBB",
    "#E4572E",
    "#76B041",
    "#000411",
    "#160C28",
    "#C1292E",
    "#020100",
    "#80B192",
    "#082D0F",
    "#A1E887",
    "#1B512D",
    "#550C18",
    "#8B635C",
    "#FF8552",
    "#B118C8",
    "#E2856E",
    "#545775"
  ];
  