import { CLEAR_PROJECT_FORM_DATA, GET_PROJECT_FORM_1_DATA } from "../actions/types"

const initialState = {
    projectDetails: {
        industry: "",
        funding: "",
        name: "",
        description: "",
        iPSensitiveDetails: [],
        status: "",
        equityID: [],
        jobs: [],
        users: [],
    },
}

export const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_FORM_1_DATA: return {
            ...state,
            projectDetails: {
                ...state.projectDetails,
                name: action.payload.name,
                industry: action.payload.industry,
                description: action.payload.description
            }
        }

        case CLEAR_PROJECT_FORM_DATA: return {
            ...state,
            projectDetails: {
                ...state.projectDetails,
                industry: "",
                funding: "",
                name: "",
                description: "",
                iPSensitiveDetails: [],
                status: "",
                equityID: [],
                jobs: [],
                users: [],
            }

        }
    }

    return state
}