import React, { useState } from "react";
import {
  Grid,
  Modal,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "../../axios";
import DropProfileImage from "../../Components/components/drop-file-input/DropProfileImage";
import "../../styles/styles.css";

const url = process.env.REACT_APP_BE_URL;


const formStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"

};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 450 },
  bgcolor: "#fff",
  boxShadow: 30,
  p: 4,
  borderRadius: "8px",
  padding: "20px",
  border: "0px",
};

function ImageChangemodel({ open, onClose, userData, updateData }) {

  // eslint-disable-next-line
  const [documentUpload, setDocumentUpload] = useState(true);
  const [imageFIle, setImageFIle] = useState();
  const [btnDisabled, setbtnDisabled] = useState(false)

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const onFileChange = async ([files]) => {
    setImageFIle(files)
    setDocumentUpload(false);
  };

  const onImageDelete = async () => {
    setbtnDisabled(true)

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payLoad = {
        profilePic: "",
      };

      const { status } = await axios.put(
        `${url}/user/${userData._id}`,
        payLoad,
        config
      );

      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Profile Picture Deleted!",
        });
        setbtnDisabled(false)
        updateData();

        onClose();

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setbtnDisabled(false)

      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const onImageSubmit = async () => {
    setbtnDisabled(true)

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payLoad = {
        profilePic: imageFIle,
      };

      const { status } = await axios.put(
        `${url}/user/${userData._id}`,
        payLoad,
        config
      );

      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Profile Picture Updated!",
        });
        setbtnDisabled(false)
        updateData();

        onClose();

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error?.data
          ? error.data
          : error.data?.message ? error.data?.message : error.response.data ? error.response.data : "Action Failed!"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Grid container justifyContent="center">
            {/* <Grid item style={TitleStyles}>
              Update Profile Picture
            </Grid> */}
            <Grid container item direction="column" alignItems="center">
              <form style={formStyles}>
                <Grid item
                  sx={{
                    border: "1px dashed ",
                    borderRadius: "8px",
                    width: { xs: 298, sm: 398 },
                  }} >
                  <div className="box">
                    <h2 className="header">Update Profile Picture</h2>
                    <DropProfileImage
                      onFileChange={(files) => onFileChange(files)}
                    />
                  </div>

                </Grid>
                <Grid item>
                  <LoadingButton
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "0.5rem",
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                    type="submit"
                    size="large"
                    onClick={onImageSubmit}
                    loading={btnDisabled}
                  >
                    Submit
                  </LoadingButton>

                  <LoadingButton
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "0.5rem",
                    }}
                    color="error"
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={onImageDelete}
                    loading={btnDisabled}
                  >
                    Delete Profile Picture
                  </LoadingButton>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>

    </>
  );
}

export default ImageChangemodel;
