import React from "react";
import { Grid, Button, Modal, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginBottom: "1rem",
  width: "100%",
};
const TitleStyles2 = {
  backgroundColor: "#fff",
  color: "#0066B2",
  fontWeight: "600",
  display: "flex",
  fontSize: "1rem",
  marginTop: "0.5rem",
  marginBottom: "1.5rem",
  width: "100%",
};


const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#fff",
  boxShadow: 20,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
};

function SignupConfirmation({ open, onClose }) {

  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate("/signin");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container justifyContent="center">
          <Grid container item direction="column" alignItems="center">
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
            >
              <Grid item style={TitleStyles}>
                Confirm It’s you...
              </Grid>
              <Grid item style={TitleStyles2}>
                We have sent a verification link to your mail
              </Grid>
              <Grid item style={TitleStyles2}>
                Check your inbox and follow the steps
              </Grid>
              <Grid container style={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Button
                  sx={{
                    borderRadius: "8px",
                    fontSize: "1rem",
                    fontWeight: "700",
                    height: "2.5rem",
                    marginTop: "1rem",
                    display: "flex",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  size="small"
                >OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default SignupConfirmation;
