import {
    Box,
    Chip,
    Grid,
    Typography,
    Snackbar,
    Alert
} from "@mui/material";
import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import axios from "../axios";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import '../styles/AllMainPagesCss.css';
import RequestModal from './requestModal'
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const url = process.env.REACT_APP_BE_URL;

const SubTitleHeaders = {
    fontWeight: "700",
    fontSize: "1rem",
    marginRight: "0.5rem",
};

const SubType = {
    fontWeight: "500",
    fontSize: "1rem",
    marginLeft: "0rem",
};

const ApprovalRequest = () => {

    const [request, setRequest] = useState(null);
    const [data, setData] = useState(null);
    const [callBack, setCallBack] = useState(false);
    const [callBackNoDataFound, setCallBackNoDataFound] = useState(false);
    const [openModel, setOpenModel] = React.useState(false);
    const [opens, setOpen] = useState(false);
    const [alert, setAlert] = useState({
        showAlert: false,
        severity: "success",
        message: "",
    });
    const [pageNumber, setPageNumber] = useState(1);
    const [count, setCount] = useState(10);

    const handlePageChange = (event, value) => {
        setPageNumber(value);
    };

    const handleCloseModel = () => {
        setOpenModel(false);
    }

    const handleSetData = (request) => {
        setData(request);
        setOpenModel(true);
    }
    const userID = JSON.parse(localStorage.getItem("userInfo"))._id
    const getRequest = async () => {
        setCallBack(true)
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
            },
        };

        const { data } = await axios.get(`${url}/request/${userID}?page_number=${pageNumber}`, config);
        setRequest(data);
        setCallBack(false)
        setCallBackNoDataFound(true)
    };

    const handleRequest = () => {
        getTotalPageCount()
        getRequest()
    };

    const getTotalPageCount = async () => {
        setCallBack(true)
        const { data } = await axios.get(`${url}/request/pageCount/${userID}`)
        setCount(data)
        setCallBack(false)
    };

    const setMessage = (e) => {
        if (e.status === 200) {
            setAlert({
                showAlert: true,
                severity: "success",
                message: e.data,
            });
        } else {
            setAlert({
                showAlert: true,
                severity: "error",
                message: e,
            });
        }
        setOpen(true);
    };

    function handleClose() {
        setOpen(false);
        setAlert({
            ...alert,
            showAlert: false,
            severity: "",
            message: "",
        });
    }

    useEffect(() => {
        getRequest();
        // eslint-disable-next-line
    }, [pageNumber]);

    useEffect(() => {
        getTotalPageCount();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <Grid container lg={12} >

                <Grid container item
                    lg={1}
                    md={1.5}
                    sm={1.8}
                    xs={1}
                >
                    <Sidebar />
                </Grid>

                <Grid item
                    marginTop={10}
                    marginBottom={5}
                    lg={10.5}
                    md={9.8}
                    sm={10}
                    xs={10}
                >
                    <Grid item>
                        <Snackbar
                            open={opens}
                            autoHideDuration={15000}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                            <Alert
                                onClose={handleClose}
                                severity={alert.severity}
                                sx={{
                                    fontSize: "16px",
                                }}
                            >
                                {alert.message}
                            </Alert>
                        </Snackbar>
                    </Grid>
                    <Grid>
                        <h2>Approval Requests</h2>
                    </Grid>
                    {!callBack ?
                        (request?.length > 0) ?
                            request.map((request) => {
                                return (
                                    <Grid key={request._id} onClick={() => handleSetData(request)} style={{ cursor: "pointer" }}>
                                        <Grid marginTop={2}>
                                            <Box border={1} borderRadius="8px" padding={2}>
                                                <Grid container>
                                                    <Grid container item sx={{
                                                        marginBottom: {
                                                            lg: "-30px",
                                                            md: "-30px",
                                                            sm: "-30px",
                                                            xs: "0px",
                                                        },
                                                        visibility: {
                                                            lg: "visible",
                                                            md: "visible",
                                                            sm: "visible",
                                                            xs: "hidden",
                                                        },
                                                    }}>
                                                        <Typography
                                                            style={SubTitleHeaders}>
                                                            {request.projectName}
                                                        </Typography>
                                                        <Typography style={{ marginRight: "0.5rem" }}>-</Typography>
                                                        <Typography
                                                            style={SubType}>
                                                            {request.type}
                                                        </Typography>
                                                        <Grid item container justifyContent="flex-end" marginTop={-3}>
                                                            <Chip
                                                                sx={{
                                                                    padding: "15px 10px 15px 10px",
                                                                    fontWeight: 400,
                                                                }}
                                                                size="small"
                                                                label={request.status}
                                                                color={
                                                                    request.status === "pending"
                                                                        ? "warning"
                                                                        : "success"
                                                                }
                                                                icon={
                                                                    request.status === "pending" ? (
                                                                        <PriorityHighIcon />
                                                                    ) : (
                                                                        <CheckIcon />
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item sx={{
                                                        marginTop: "-30px",
                                                        visibility: {
                                                            lg: "hidden",
                                                            md: "hidden",
                                                            sm: "hidden",
                                                            xs: "visible",
                                                        },
                                                    }}>
                                                        <Typography
                                                            style={SubTitleHeaders}>
                                                            {request.projectName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item sx={{
                                                        visibility: {
                                                            lg: "hidden",
                                                            md: "hidden",
                                                            sm: "hidden",
                                                            xs: "visible",
                                                        },
                                                    }}>
                                                        <Typography
                                                            style={SubType}>
                                                            {request.type}
                                                        </Typography>
                                                        <Grid item container justifyContent="flex-end" marginTop={-3}>
                                                            <Chip
                                                                sx={{
                                                                    padding: "15px 10px 15px 10px",
                                                                    fontWeight: 400,
                                                                }}
                                                                size="small"
                                                                label={request.status}
                                                                color={
                                                                    request.status === "pending"
                                                                        ? "warning"
                                                                        : "success"
                                                                }
                                                                icon={
                                                                    request.status === "pending" ? (
                                                                        <PriorityHighIcon />
                                                                    ) : (
                                                                        <CheckIcon />
                                                                    )
                                                                } />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            : callBackNoDataFound ? <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="center"
                                style={{ height: "60vh" }}
                            >
                                <Grid item>
                                    <Typography variant="h4" fontWeight="900">
                                        You have no pending approval requests
                                    </Typography>
                                </Grid>
                            </Grid> : null
                        :
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="center"
                            style={{ height: "60vh" }}
                        >
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>}
                    {count > 1 ?
                        <Grid container justifyContent={"center"} sx={{ marginTop: { xs: 1 } }}>
                            {!callBack && callBackNoDataFound ?
                                <Stack>
                                    <Pagination
                                        count={count}
                                        page={pageNumber}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                        shape="rounded"
                                        style={{
                                            transform: "scale(0.8)",
                                        }}
                                    />
                                </Stack> : <p>Loading...</p>}
                        </Grid> : null}
                    <RequestModal open={openModel} onClose={handleCloseModel} data={data} callBackDoadData={handleRequest} setMessage={setMessage} />
                </Grid >
            </Grid >

        </>
    );
};

export default ApprovalRequest;
