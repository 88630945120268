import React from "react";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import axios from "../axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import Modal from '@mui/material/Modal';
import axiosInstance from "../axios";
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from "@mui/lab/LoadingButton";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { nanoid } from "nanoid"

const url = process.env.REACT_APP_BE_URL;

const bakdropStyle = {
  bgcolor: 'rgba(0, 0, 0, 0.26)',
};

const modelStyle = {
  pl: {
    lg: 4,
    md: 4,
    sm: 4,
    xs: 3,
  },
  pr: {
    lg: 4,
    md: 4,
    sm: 4,
    xs: 3,
  },
};

const listStyle = {
  marginBottom: {
    lg: "0.5rem",
    md: "0.5rem",
    sm: "0.5rem",
    xs: "1rem",
  },
}

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const PublishModal = ({
  openPublishmodel,
  setPublishmodel,
  getData
}) => {

  //list messgeges
  const [listmessage, setListmessage] = useState();
  //invite collaborators form
  const [value, setValue] = useState({
    collabname: "",
    email: "",
    title: ""
  });

  const [invitebtnDisabled, setinvitebtnDisabled] = useState(false);
  const [publishBtnDisabled, setpublishBtnDisabled] = useState(false);


  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const { projId } = useParams();
  //validation
  const [errors, setErrors] = useState({});

  // eslint-disable-next-line
  const [emailalradyExsist, setEmailalradyExsist] = useState(false);

  //publish project
  const publishProject = async () => {

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    setpublishBtnDisabled(true)

    try {
      const res = await axiosInstance.put(
        `${url}/project/${projId}`,
        {
          status: "published",
        },
        config
      );

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Project updated successfully"}`
        })
        setPublishmodel(false)
        setpublishBtnDisabled(false)
        getData()
      }
    } catch (error) {
      setpublishBtnDisabled(false)
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      getData()
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  }

  const modalClose = () => {
    setListmessage(false);
    setPublishmodel(false);
    setinviteOpen(false);

    setValue({
      collabname: "",
      email: "",
      title: ""
    })
    setColabs([])

  };

  //invite collaborators
  const [inviteOpen, setinviteOpen] = useState(false)
  const openInviteCollab = () => {
    setinviteOpen(true);
  }

  const closeInviteCollab = () => {
    setinviteOpen(false)

    setColabs([])

    setValue({
      collabname: "",
      email: "",
      title: ""
    })
  }

  //invite post
  const inviteSubmit = async () => {

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    setinvitebtnDisabled(true)

    const newArr = listColabs.map(({ listId, ...rest }) => {
      return rest;
    });

    try {
      const res = await axios.post(`${url}/invite/inviteCollaborator/${projId}`, newArr,
        config);

      if (res.status === 200) {

        setAlert({
          showAlert: true,
          severity: "success",
          message: "Action successful!",
        })

        setListmessage(res.data);
        setinvitebtnDisabled(false)
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          })

        }, 16000);

      }
    } catch (error) {
      setinvitebtnDisabled(false)
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);

    }
  };

  // change values of input
  const chanageInputVal = (e) => {
    //set user object
    setValue(prevFormData => {
      return {
        ...prevFormData,
        [e.target.name]: e.target.value
      }
    })
    //email validation
    if (e.target.name === "email") {
      const reg = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
      setErrors(reg.test(e.target.value));
    }
  }

  //on add -> values to new state
  const [listColabs, setColabs] = useState([]);

  const addValuestoList = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      const { data } = await axios.get(`${url}/user/found/${value.email}?projectID=${projId}`, config);
      if (data === "Collaborator found") {
        if (!listColabs.find(emails => emails.email === value.email)) {
          setColabs(oldVal => [
            {
              listId: nanoid(),
              collabname: value.collabname,
              email: value.email,
              title: value.title
            },
            ...oldVal
          ])
          setEmailalradyExsist(false)
        } else {
          setAlert({
            showAlert: true,
            severity: "error",
            message: "Email already exist",
          })
        }
        setValue({
          collabname: "",
          email: "",
          title: ""
        })
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  }

  //close snackbar
  function handleClose() {
    setAlert({
      showAlert: false,
      severity: "",
      message: "",
    })
  }

  //remove item from row
  const deleteRow = (event, userId) => {
    event.stopPropagation()
    setColabs(oldUsers => oldUsers.filter(user => user.listId !== userId))
  }

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={alert.severity}
          onClose={handleClose}
          sx={{
            fontSize: "16px"
          }}>
          {alert.message}
        </Alert>
      </Snackbar>

      {openPublishmodel && <Grid sx={bakdropStyle}>
        <Modal
          sx={modelStyle}
          open={openPublishmodel}
          onClose={modalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {!inviteOpen ? (<Box
            border={1}
            borderRadius="8px"
            style={{
              position: "relative",
              top: '20vh',
            }}
            sx={{
              backgroundColor: "white",
              right: '15%',
              left: {
                lg: '23vw',
                md: '23vw',
                sm: '16vw',
                xs: '0vw',
              },
              width: {
                lg: "50vw",
                md: "60vw",
                sm: "70vw",
                xs: "100%",
              },
              padding: {
                lg: "3rem",
                md: "3rem",
                sm: "3rem",
                xs: "4rem",
              },
            }}
          >
            <Grid item
              sx={{
                textAlign: {
                  lg: "left",
                  md: "left",
                  sm: "center",
                  xs: "left",
                },
                mb: "2rem",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  wordBreak: "break-word",
                }}
              >
                Add any collaborators/co-founders for the project,
                you cannot add after the project is published.</Typography>

            </Grid>

            <Grid container item sx={listStyle}
              alignItems="center"
              justifyContent="center" >

              <Grid item
                lg={"auto"}
                md={"auto"}
                sm={"auto"}
                xs={12}

                sx={{ textAlign: "center" }}
              >
                <LoadingButton
                  disabled={publishBtnDisabled}
                  loading={publishBtnDisabled}
                  color="success"
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    ml: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "0rem",
                    },
                    mt: {
                      lg: "0rem",
                      md: "0rem",
                      sm: "0rem",
                      xs: "1rem",
                    },
                  }}
                  onClick={publishProject}
                >
                  Publish
                </LoadingButton>
              </Grid>

              <Grid item
                lg={"auto"}
                md={"auto"}
                sm={"auto"}
                xs={12}
                sx={{ textAlign: "center" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    ml: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "0rem",
                    },
                    mt: {
                      lg: "0rem",
                      md: "0rem",
                      sm: "0rem",
                      xs: "1rem",
                    },
                  }}
                  onClick={openInviteCollab}
                >
                  Invite collaborator
                </Button>
              </Grid>

            </Grid>
          </Box>) : (

            <Box
              border={1}
              borderRadius="8px"
              sx={{
                position: "relative",
                top: {
                  lg: '2vh',
                  md: '3vh',
                  sm: '3vh',
                  xs: '1vh',
                },

                backgroundColor: "white",
                right: {
                  lg: '15%',
                  md: '15%',
                  sm: '15%',
                  xs: '0',
                },
                left: {
                  lg: '23vw',
                  md: '23vw',
                  sm: '14vw',
                  xs: '0vw',
                },
                width: {
                  lg: "50vw",
                  md: "60vw",
                  sm: "70vw",
                  xs: "100%",
                },
                padding: {
                  lg: "3rem",
                  md: "3rem",
                  sm: "3rem",
                  xs: "2rem",
                },
              }}
            >

              <Grid container>
                {!listmessage &&
                  <Grid container item
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item
                      sx={{
                        textAlign: {
                          lg: "left",
                          md: "left",
                          sm: "center",
                          xs: "left",
                        },
                      }}
                    >

                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "1rem",
                          wordBreak: "break-word",
                        }}
                      >
                        Add collaborators/co-founders for the project</Typography>
                    </Grid>

                    <Grid container justifyContent="center" sx={{
                      paddingTop: {
                        lg: "40px",
                        md: "40px",
                        sm: "40px",
                        xs: "1rem",
                      }
                    }}>
                      <Grid container item direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <form style={formStyles} >
                          <Grid container>
                            <Grid container item
                              alignItems="center"
                              justifyContent="center"
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Grid item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <TextField
                                  value={value.collabname}
                                  onChange={(event) => chanageInputVal(event)}
                                  sx={{
                                    pl: {
                                      lg: "0.3rem",
                                      md: "0.3rem",
                                      sm: "0.3rem",
                                      xs: "0rem",
                                    },
                                    pr: {
                                      lg: "0.3rem",
                                      md: "0.3rem",
                                      sm: "0.3rem",
                                      xs: "0rem",
                                    },
                                    mt: {
                                      lg: "0.5rem",
                                      md: "0.5rem",
                                      sm: "0.5rem",
                                      xs: "0rem",
                                    },
                                    mb: "0.5rem",
                                    width: "100%"
                                  }}
                                  label="Name"
                                  name="collabname" />
                              </Grid>

                              <Grid item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <TextField
                                  error={!errors}
                                  helperText={!errors ? "Type correct email!" : ""}
                                  value={value?.email}
                                  name="email"
                                  type="email"
                                  onChange={(event) => chanageInputVal(event)}
                                  sx={{
                                    pl: {
                                      lg: "0.3rem",
                                      md: "0.3rem",
                                      sm: "0.3rem",
                                      xs: "0rem",
                                    },
                                    pr: {
                                      lg: "0.3rem",
                                      md: "0.3rem",
                                      sm: "0.3rem",
                                      xs: "0rem",
                                    },
                                    mt: "0.5rem",
                                    mb: "0.5rem",
                                    width: "100%"
                                  }}
                                  label="Email"
                                  required
                                />
                              </Grid>

                              <Grid item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <TextField
                                  name="title"
                                  value={value.title}
                                  onChange={(event) => chanageInputVal(event)}
                                  sx={{
                                    pl: {
                                      lg: "0.3rem",
                                      md: "0.3rem",
                                      sm: "0.3rem",
                                      xs: "0rem",
                                    },
                                    pr: {
                                      lg: "0.3rem",
                                      md: "0.3rem",
                                      sm: "0.3rem",
                                      xs: "0rem",
                                    },
                                    mt: "0.5rem",
                                    mb: "1rem",
                                    width: "100%"
                                  }}
                                  label="Title" />
                              </Grid>

                              <Grid item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <Button
                                  onClick={addValuestoList}
                                  color="success"
                                  variant="contained"
                                  size="large"
                                  disabled={!errors || !value.email}
                                  sx={{
                                    width: {
                                      lg: "100%",
                                      md: "100%",
                                      sm: "100%",
                                      xs: "100%",
                                    },
                                    mb: "0.6rem",
                                  }}
                                >
                                  + Add
                                </Button>
                              </Grid>

                            </Grid>

                            {listColabs && <Grid container item
                              alignItems="center"
                              justifyContent="center" >

                              <TableContainer
                                component={Paper}
                                sx={{
                                  height: {
                                    lg: `${listColabs?.length !== 0 ? "15rem" : "0rem"}`,
                                    md: `${listColabs?.length !== 0 ? "10rem" : "0rem"}`,
                                    sm: `${listColabs?.length !== 0 ? "8rem" : "0rem"}`,
                                    xs: `${listColabs?.length !== 0 ? "10rem" : "0rem"}`,
                                  }, maxWidth: {
                                    lg: "100%",
                                    md: "100%",
                                    sm: "100%",
                                    xs: "65vw",
                                  }
                                }}>
                                <Table aria-label="simple table">
                                  <TableHead>
                                    <TableRow >
                                      <TableCell align="center" >Name</TableCell>
                                      <TableCell align="center" >Email</TableCell>
                                      <TableCell align="center" >Title</TableCell>
                                      <TableCell align="center" >Remove</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {listColabs?.map((row, i) => (
                                      <TableRow key={i} >
                                        <TableCell align="center" >
                                          {row.collabname}
                                        </TableCell>
                                        <TableCell align="center" >{row.email}</TableCell>
                                        <TableCell align="center" >{row.title}</TableCell>
                                        <TableCell align="center" >
                                          <Button
                                            onClick={(event) => deleteRow(event, row.listId)}
                                          ><DeleteIcon color="error" /></Button>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                            </Grid>}

                          </Grid>

                          <Grid item>
                            <Button
                              onClick={inviteSubmit}
                              sx={{
                                borderRadius: "8px",
                                fontSize: "1rem",
                                fontWeight: "700",
                                height: "2.5rem",
                                marginTop: "1rem",
                              }}
                              disabled={listColabs.length <= 0 || invitebtnDisabled}
                              color="primary"
                              fullWidth
                              variant="contained"
                              type="submit"
                              size="large"
                            >
                              Invite
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              color="primary"
                              fullWidth
                              variant="text"
                              size="large"
                              onClick={closeInviteCollab}
                            >
                              Cancel
                            </Button>
                          </Grid>

                        </form>
                      </Grid>
                    </Grid>
                  </ Grid>}


                {listmessage &&

                  <Grid container item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "2rem",
                        wordBreak: "break-word",
                      }}
                      mb={1}
                    > Status </Typography>

                    <List sx={{
                      width: '100%',
                      height: "20rem",
                      overflowY: "auto",
                      bgcolor: 'background.paper'
                    }}>

                      {listmessage?.map((mapValue, i) => {
                        return (
                          <Grid item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ boxShadow: 2 }}
                          >
                            <ListItem
                              key={i}
                              sx={{
                                textAlign: "center",
                                borderTop: "0rem",
                                borderBottom: "0.1px",
                                borderLeft: "0rem",
                                borderRight: "0rem",
                                borderStyle: "solid",
                                borderColor: "#E1E1E1",
                              }} >
                              <ListItemText
                                primary={mapValue.email}
                                secondary={
                                  <Typography
                                    sx={{
                                      color: `${(mapValue.message === "The user is already member in this project!" ||
                                        mapValue.message === "Email Already Exists!") ? "#F53D3D" : "#4CB944"}`
                                    }}
                                  >
                                    {mapValue.message}
                                  </Typography>
                                } />
                            </ListItem>
                          </Grid>
                        );
                      })}
                    </List>
                  </Grid>
                }
              </ Grid>
            </Box>
          )}
        </Modal >
      </Grid>}
    </>
  );
};

export default PublishModal;
