import React from "react";
import { Button, } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import Navbar from "../../../Components/Navbar";
// import Sidebar from "../../../Components/Sidebar"; 

function Nda() {

  const navigate = useNavigate();

  return (
    <>

      {/* <Navbar />

      <Grid container>

        <Grid container item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={1}
        >
          <Sidebar />
        </Grid> */}

      <Button
        variant="contain"
        onClick={() => navigate("/ndaAcknowledgement")}
      >Acknowledgement</Button>

      <Button
        variant="contain"
        onClick={() => navigate("/NdaStep1")}
      >NDA Step 1</Button>

      <Button
        variant="contain"
        onClick={() => navigate("/NdaStep2")}
      >NDA Step 2</Button>

      {/* </Grid> */}

    </>
  );
}

export default Nda;
