import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { jobReducer } from "./jobReducer";
import { projectReducer } from "./projectReducer";


export default combineReducers({
  auth: authReducer,
  project: projectReducer,
  job: jobReducer,
});
