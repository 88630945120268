import React, { useState } from "react";
import {
  Grid,
  Button,
  Modal,
  Box,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";

import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    lg: 500,
    md: 480,
    sm: 450,
    xs: "89%",
  },
  bgcolor: "#fff",
  boxShadow: 30,
  borderRadius: "8px",
  border: "none",
  padding: "10px 10px 3.6rem 10px",
};

function NdaStep1() {

  const { ndaId } = useParams();
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  return (
    <>

      <Navbar />

      <Grid container>

        <Grid container item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={1}
        >
          <Sidebar />
        </Grid>

        <Snackbar
          sx={{ mt: "8vh" }}
          open={alert.showAlert}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={alert.severity}
            sx={{
              fontSize: "16px",
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>

        <Modal
          open={true} >
          <Box sx={style}>
            <Grid container justifyContent="center">
              <Grid item
                lg={10}
                md={10}
                sm={10}
                xs={10}
              >
                <Typography
                  style={TitleStyles}
                  sx={{
                    fontSize: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "1.5rem",
                      xs: "1.4rem",
                    },
                    marginTop: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "2rem",
                      xs: "1rem",
                    },
                  }}
                >
                  Step 1
                </Typography>

                <Typography
                  paragraph
                  sx={{
                    marginTop: "1rem",
                    wordBreak: "break-word",
                    fontSize: {
                      lg: "0.95rem",
                      md: "0.95rem",
                      sm: "0.94rem",
                      xs: "0.95rem",
                    },
                    height: {
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "12rem",
                    },
                    overflowY: "auto"
                  }}
                >
                  My transmission of this application as an "electronic record" containing my
                  "electronic signature," as those terms are defined in applicable federal
                  and/or state laws (excluding audio and video recordings), or my facsimile (copy of original)
                  transmission of this application containing a facsimile of my signature,
                  shall be as effective, enforceable and valid as if a paper version of this
                  application were delivered containing my original written signature.
                </Typography>

              </Grid>
              <Grid container item
                lg={10}
                md={10}
                sm={10}
                xs={10}
                direction="column" alignItems="center">
                <Grid item>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: {
                        lg: "0.3rem",
                        md: "0.5rem",
                        sm: "0.5rem",
                        xs: 0,
                      },
                      textTransform: "capitalize"
                    }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                    onClick={() => navigate(`/NdaStep2/${ndaId}`)}
                  >
                    I Agree
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>

      </Grid>

    </>
  );
}

export default NdaStep1;
