// Auth actions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

//Project actions
export const GET_PROJECT_FORM_1_DATA = "GET_PROJECT_FORM_1_DATA"
export const CLEAR_PROJECT_FORM_DATA = "CLEAR_PROJECT_FORM_DATA"

//Job actions
export const GET_JOB_FORM_1_DATA = "GET_JOB_FORM_1_DATA"
export const GET_JOB_FORM_2_DATA = "GET_JOB_FORM_2_DATA"
export const CLEAR_JOB_FORM_DATA = "CLEAR_JOB_FORM_DATA"

