import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../axios";

const year = new Date().getFullYear();
const url = process.env.REACT_APP_BE_URL;

let SignUpSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Too short")
    .max(50, "Too long"),
  companyName: Yup.string()
    .required("Company Name is required")
    .min(3, "Too short")
    .max(1000, "Too long"),
  location: Yup.string()
    .required("Location is required")
    .min(3, "Too short")
    .max(20, "Too long"),
  industry: Yup.string()
    .required("Industry is required")
    .min(3, "Too short")
    .max(25, "Too long"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginBottom: "0.5rem",
};

const fieldStyles = {
  width: { xs: 298, sm: 398 },
  marginBottom: "5px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 30,
  borderRadius: "8px",
  padding: "20px",
  border: "0px",
};

const employmentTypes = ["Full-time", "Part-time", "Self-employed", "Freelance", "Contract", "Internship", "Apprenticeship", "Seasonal"]

function Experiance({
  open,
  onClose,
  isEditData,
  setIndex,
  index,
  setIsEditData,
  data,
  getData,
  check,
  setCheck
}) {
  const [initialValues, setInitialValues] = useState({
    title: "",
    companyName: "",
    location: "",
    industry: "",
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [employmentType, setEmploymentType] = useState("Full-time");
  const isMounted = useRef(false);
  const [startDateArray, setStartDateArray] = useState([]);
  const [endDateArray, setEndDateArray] = useState([]);
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');

  //select formik setup
  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (

      <Select
        // required
        label="industry"
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };
  const handleChangeStart = (event) => {
    endYearRange(event.target.value)
    setStartYear(event.target.value)
  };

  const handleChangeEnd = (event) => {
    setEndYear(event.target.value)
  };
  const startYearRange = () => {
    let newarray = []
    let currentYear = new Date().getFullYear()
    for (let i = 0; i < 50; i++) {
      newarray.push(currentYear - i)
      if (i === 49) {
        setStartDateArray([...newarray])
        if (data?.pastExperience[index]?.startYear) {
          const spliteArray = newarray.filter((item) => item >= data?.pastExperience[index]?.startYear)
          setEndDateArray([...spliteArray])
        } else {
          setEndDateArray([...newarray])
        }
      }
    }
  }

  const endYearRange = (startYear) => {
    const spliteArray = startDateArray.filter((item) => item >= startYear)
    setEndDateArray([...spliteArray])
  }

  const handleChange = (event) => {
    setEmploymentType(event.target.value)
  };

  useEffect(() => {
    if (isMounted.current) {
      if (employmentType !== "") {
        setCheck(false)
      }
    } else {
      isMounted.current = true;
    }
  }, [employmentType])

  useEffect(() => {
    if (isEditData) {
      setInitialValues({
        title: data?.pastExperience[index]?.title,
        companyName: data?.pastExperience[index]?.companyName,
        location: data?.pastExperience[index]?.location,
        industry: data?.pastExperience[index]?.industry,
      });
      setStartYear(data?.pastExperience[index]?.startYear)
      setEndYear(data?.pastExperience[index]?.endYear)
      setEmploymentType(data?.pastExperience[index]?.employmentType,)
    } else {
      setInitialValues({
        title: "",
        companyName: "",
        location: "",
        industry: "",
      });
      setEmploymentType("")
    }
    startYearRange()
  }, [data, index, isEditData]);

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const items = JSON.parse(localStorage.getItem("userInfo"));

  const handleSubmit = async (values, { resetForm }) => {
    if (startYear <= endYear || endYear === "") {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };

      try {
        let payLoad;
        let newValues = { ...values, employmentType: employmentType, startYear: startYear, endYear: endYear }
        if (!isEditData) {
          payLoad = {
            pastExperience: [...data.pastExperience, newValues],
          };
        } else {
          data.pastExperience[index] = newValues;
          payLoad = {
            pastExperience: data.pastExperience,
          };
        }

        const { status } = await axios.put(
          `${url}/user/${items._id}`,
          payLoad,
          config
        );
        resetForm();
        onClose();
        setIsEditData(false);
        getData();
        if (status === 200 && isEditData) {
          setAlert({
            showAlert: true,
            severity: "success",
            message: "Experience details are edited successfully!",
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: "success",
              message: "",
            });
          }, 16000);
        } else if (status === 200 && !isEditData) {
          setAlert({
            showAlert: true,
            severity: "success",
            message: "Experience details are added successfully!",
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: "success",
              message: "",
            });
          }, 16000);
        }
      } catch (error) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    }
  };
  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid item style={TitleStyles}>
              Experience
            </Grid>
            <Grid container item direction="column" alignItems="center">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={SignUpSchema}
              >
                {({ errors, isValid, touched, dirty, values }) => (
                  <Form style={formStyles}>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="title"
                        label="Title"
                        as={TextField}
                        variant="outlined"
                        type="text"
                        required
                        size="small"
                        margin="dense"
                        error={Boolean(errors.title) && Boolean(touched.title)}
                        helperText={Boolean(touched.title) && errors.title}
                      ></Field>
                    </Grid>
                    <Grid item>
                      <TextField
                        sx={fieldStyles}
                        select
                        label="Employment Type"
                        value={employmentType}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"

                        helperText="Please select an employment type"
                      >
                        {employmentTypes?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item>
                      <TextField
                        sx={fieldStyles}
                        select
                        name="startYear"
                        label="Start Year"
                        value={startYear}
                        onChange={handleChangeStart}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        helperText="Please select a start year"
                      >
                        {startDateArray?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item>
                      <TextField
                        sx={fieldStyles}
                        select
                        name="endYear"
                        label="End Year( Not required if present employment )"
                        value={endYear}
                        onChange={handleChangeEnd}
                        variant="outlined"
                        size="small"
                        margin="dense"
                        helperText="Please select end year"
                      >
                        {endDateArray?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="companyName"
                        label="Company Name"
                        as={TextField}
                        variant="outlined"
                        type="text"
                        required
                        size="small"
                        margin="dense"
                        error={
                          Boolean(errors.companyName) &&
                          Boolean(touched.companyName)
                        }
                        helperText={
                          Boolean(touched.companyName) && errors.companyName
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="location"
                        label="Location"
                        as={TextField}
                        variant="outlined"
                        type="text"
                        required
                        size="small"
                        margin="dense"
                        error={
                          Boolean(errors.location) && Boolean(touched.location)
                        }
                        helperText={
                          Boolean(touched.location) && errors.location
                        }
                      ></Field>
                    </Grid>
                    <Grid item>

                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth required>
                          <InputLabel
                            id="demo-simple-select-label"
                            // sx={fieldStyles}
                            name="industry"
                          >
                            Industry
                          </InputLabel>
                          <Field
                            component={CustomizedSelectForFormik}
                            name="industry"
                            as={Select}

                            error={
                              Boolean(errors.industry) && Boolean(touched.industry)
                            }
                            helperText={Boolean(touched.industry) && errors.industry}
                          >

                            <MenuItem value="Consumer Discretionary">Consumer Discretionary</MenuItem>
                            <MenuItem value="Consumer Staples">Consumer Staples</MenuItem>
                            <MenuItem value="Construction">Construction</MenuItem>
                            <MenuItem value="Energy">Energy</MenuItem>
                            <MenuItem value="Materials">Materials</MenuItem>
                            <MenuItem value="Industrials">Industrials</MenuItem>
                            <MenuItem value="Healthcare">Healthcare</MenuItem>
                            <MenuItem value="Financials">Financials</MenuItem>
                            <MenuItem value="Information Technology">Information Technology</MenuItem>
                            <MenuItem value="Real Estate">Real Estate</MenuItem>
                            <MenuItem value="Communication Services">Communication Services</MenuItem>
                            <MenuItem value="Utilities">Utilities</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Field>
                        </FormControl>
                      </Box>

                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          height: "2.5rem",
                          marginTop: "0.5rem",
                        }}
                        color="primary"
                        fullWidth
                        variant="contained"
                        disabled={!isValid && check && startYear}
                        type="submit"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default Experiance;
