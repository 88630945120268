import React, { useState } from "react";
import { Grid, Button, TextField, Box, Select, FormControl, InputLabel } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Drawer from "../Components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { getForm1Data } from "../store/actions/projectActions";

let CreateProject1Schema = Yup.object().shape({
  projectName: Yup.string()
    .required("Project Name is required")
    .min(3, "Too short")
    .max(40, "Too long"),

  industry: Yup.string()
    .required("Industry is required"),

  description: Yup.string()
    .required("Project Description is required")
    .min(100, "Too short")
    .max(5000, "Too long"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",

  fontSize: "2rem",
  marginTop: "3rem",
  marginBottom: "3rem",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};


//select formik setup
const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (

    <Select
      // required
      label="industry"
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

function CreateProject1() {

  const dispatch = useDispatch()
  const data = useSelector((state) => state?.project?.projectDetails)


  const navigate = useNavigate();
  const [initialValues1, setInitialValues1] = useState({
    projectName: data ? data.name : "",
    industry: data ? data.industry : "",
    description: data ? data.description : "",
  });

  const handleSubmit1 = async (values, onSubmitProps) => {
    let payload = {
      name: values.projectName,
      industry: values.industry,
      description: values.description
    }

    dispatch(getForm1Data(payload))

    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
    setInitialValues1({
      projectName: "",
      industry: "",
      description: "",
    });
    navigate("/createproject2", { state: data });
  };

  return (
    <>
      <Drawer />
      <Grid container justifyContent="center" sx={{ paddingTop: "70px" }}>
        <Grid item style={TitleStyles}>
          Public Details
        </Grid>
        <Grid container item direction="column" alignItems="center">
          <Formik
            initialValues={initialValues1}
            onSubmit={handleSubmit1}
            validationSchema={CreateProject1Schema}
          >
            {({ errors, isValid, touched, dirty }) => (
              <Form style={formStyles}>
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="projectName"
                    label={"Project Name"}
                    as={TextField}
                    variant="outlined"
                    required
                    size="small"
                    margin="dense"
                    error={
                      Boolean(errors.projectName) &&
                      Boolean(touched.projectName)
                    }
                    helperText={
                      Boolean(touched.projectName) && errors.projectName
                    }
                  ></Field>
                </Grid>
                <Grid item>

                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth required>
                      <InputLabel
                        id="demo-simple-select-label"
                        // sx={fieldStyles}
                        name="industry"
                      >
                        Industry
                      </InputLabel>
                      <Field
                        component={CustomizedSelectForFormik}
                        name="industry"
                        as={Select}

                        error={
                          Boolean(errors.industry) && Boolean(touched.industry)
                        }
                        helperText={Boolean(touched.industry) && errors.industry}
                      >

                        <MenuItem value="Consumer Discretionary">Consumer Discretionary</MenuItem>
                        <MenuItem value="Consumer Staples">Consumer Staples</MenuItem>
                        <MenuItem value="Construction">Construction</MenuItem>
                        <MenuItem value="Energy">Energy</MenuItem>
                        <MenuItem value="Materials">Materials</MenuItem>
                        <MenuItem value="Industrials">Industrials</MenuItem>
                        <MenuItem value="Healthcare">Healthcare</MenuItem>
                        <MenuItem value="Financials">Financials</MenuItem>
                        <MenuItem value="Information Technology">Information Technology</MenuItem>
                        <MenuItem value="Real Estate">Real Estate</MenuItem>
                        <MenuItem value="Communication Services">Communication Services</MenuItem>
                        <MenuItem value="Utilities">Utilities</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Field>
                    </FormControl>
                  </Box>

                </Grid>
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="description"
                    label="Project Description (Min. 100 characters)"
                    as={TextField}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    multiline
                    rows={10}
                    error={
                      Boolean(errors.description) &&
                      Boolean(touched.description)
                    }
                    helperText={
                      Boolean(touched.description) && errors.description
                    }
                  ></Field>
                </Grid>

                <Grid item>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "1rem",
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                    disabled={!dirty || !isValid}
                    type="submit"
                    size="large"
                  >
                    Continue
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateProject1;
