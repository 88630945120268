import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import axios from "../axios";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from "react-router-dom";
import '../styles/AllMainPagesCss.css';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Formik, Form, Field } from "formik";

const url = process.env.REACT_APP_BE_URL;

const fieldStyles = {
  margin: "1rem 0 1rem 0",
  width: "18rem",
  fontSize: "1rem",
};
const SubTitleHeaders = {
  fontWeight: "700",
  fontSize: "1.2rem",
};

const listItemStyles = {
  fontSize: "0.75rem",
  lineHeight: "1.3rem",
};

const JobSearch = () => {
  const [jobs, setJobs] = useState(null);
  const [value, setValue] = useState("");
  const [timeAvailability, setTimeAvailability] = useState("none");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(10);
  const [callback, setCallback] = useState(false);
  const initialValues = { searchName: "" }
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setPageNumber(1)
    if (pageNumber === 1) {
      getSearch(value);
    }
    // eslint-disable-next-line
  }, [timeAvailability]);

  useEffect(() => {
    if (value === "") {
      setPageNumber(1)
      if (pageNumber === 1) {
        getSearch(value);
      }
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    getSearch(value);
    // eslint-disable-next-line
  }, [pageNumber, checked]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const getSearch = async (value) => {
    setCallback(true)
    let arrangedUR = "";
    if (checked) {
      if (value !== "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}?title=${value}`;
      } else if (value === "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}`;
      } else if (value !== "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}?title=${value}&timeAvailability=${timeAvailability}`;
      } else if (value === "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}?timeAvailability=${timeAvailability}`;
      }
    } else {
      if (value !== "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}?title=${value}`;
      } else if (value === "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}`;
      } else if (value !== "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}?title=${value}&timeAvailability=${timeAvailability}`;
      } else if (value === "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}?timeAvailability=${timeAvailability}`;
      }
    }

    setValue(value);
    setJobs(null);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    const { data } = await axios.get(arrangedUR, config);
    setJobs(data?.data);
    setCount(data?.allPageCount)
    setCallback(false)
  };

  const handleRadioChange = (event) => {
    setTimeAvailability(event.target.value);
  };

  const handleSearchEnter = (e) => {
    setPageNumber(1)
    getSearch(e.searchName);
  };

  const handleReset = (e) => {
    setPageNumber(1)
    setValue('')
  };

  return (
    <>
      <Navbar />

      <Grid container lg={12} >

        <Grid container item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={1}
        >
          <Sidebar />
        </Grid>

        <Grid container item
          marginTop={10}
          marginBottom={5}
          lg={10.5}
          md={9.8}
          sm={10}
          xs={10}
        >

          <Grid
            container
            direction="column"
            alignItems="center"
            marginBottom="2rem"
          >
            <Grid container item direction="row"
              justifyContent={{
                lg: "flex-start",
                md: "center",
                sm: "center",
                xs: "center",
              }} >
              <Grid item
                xs={"auto"}
                sm={"auto"}
                md={"auto"}
                lg={4}
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSearchEnter}
                >
                  {({ values }) => (
                    <Form>
                      <Field
                        label="Search Jobs"
                        name="searchName"
                        size="small"
                        style={fieldStyles}
                        as={TextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                onClick={() => {
                                  handleSearchEnter(values)
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      ></Field>
                      <Button type="reset" onClick={() => { handleReset() }} style={{ marginTop: "1rem" }}>
                        Reset
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Grid>

              <Grid item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                sx={{
                  marginBottom: "15px",
                }}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{
                      marginTop: "15px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Time Availability
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={timeAvailability}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="full-time"
                      control={<Radio />}
                      label="Full-time"
                    />
                    <FormControlLabel
                      value="part-time"
                      control={<Radio />}
                      label="Part-time"
                    />
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="Any"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} sx={{ marginBottom: "15px" }}>
                <FormGroup>
                  <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label={<Typography sx={{ fontWeight: "bold", color: "black" }}>Applied jobs</Typography>} />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid item container spacing={3} marginTop={1} >
              {jobs ? (
                jobs?.length > 0 ? (
                  jobs.map((job) => {
                    return (
                      <Grid item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        key={job._id}>
                        <Link to={`/JobDetails/${job._id}`} style={{ textDecoration: "none", color: "initial", }} >
                          <Box
                            border={1}
                            borderRadius="8px"
                            padding={2}
                            sx={{ cursor: "pointer" }} >
                            <Grid container>
                              <Grid container item marginBottom={1}>
                                <Typography
                                  color="primary"
                                  style={SubTitleHeaders}
                                  sx={{
                                    flexGrow: 1,
                                    wordBreak: "break-word",

                                  }}
                                >
                                  {job.title}
                                </Typography>
                              </Grid>

                              <Grid container item justifyContent="flex-start" >

                                <Grid item xs={12}>
                                  <Grid container item justifyContent="flex-start">
                                    <Grid item xs={12} sx={{
                                      wordBreak: "break-word",
                                      height: {
                                        xl: "5rem",
                                        lg: "7rem",
                                        md: "8rem",
                                        sm: "9rem",
                                        xs: "100px",
                                      },
                                    }}>
                                      <List dense disablePadding disableGutters>
                                        <ListItem
                                          disablePadding
                                          disableGutters
                                          sx={{ display: "list-item" }}
                                        >
                                          <Typography
                                            className="projectPara"
                                            style={{
                                              fontSize: "0.75rem",
                                              lineHeight: "1.3rem",
                                            }}>
                                            {job.description}
                                          </Typography>
                                        </ListItem>
                                      </List>
                                    </Grid>
                                  </Grid>

                                  <Grid container item justifyContent="flex-start" paddingTop={3} >
                                    <List dense disablePadding disableGutters>
                                      <ListItem
                                        disablePadding
                                        sx={{
                                          display: "list-item",
                                        }}
                                      ><Typography sx={listItemStyles}>
                                          Job Type - {job.jobType}
                                        </Typography>

                                        <Typography sx={listItemStyles}>
                                          Availability - {job.timeAvailability}
                                        </Typography>
                                        <Typography sx={listItemStyles}>
                                          Units - {job.units} (
                                          {(
                                            (parseInt(job.units) / (parseInt(job?.projectID?.totalUnits))) *
                                            100
                                          ).toFixed(2)}
                                          %)
                                        </Typography>
                                      </ListItem>
                                    </List>
                                  </Grid>

                                  <List sx={{ overflow: "hidden", paddingBottom: "1.3rem" }}>
                                    <Typography fontWeight="600" fontSize="0.9rem">
                                      Required skills
                                    </Typography>
                                    <Grid
                                      direction="row"
                                      spacing="3px"
                                      xs={12}
                                      container
                                      item
                                      sx={{
                                        height: {
                                          sm: "40px",
                                          md: "40px",
                                          lg: "40px",
                                          xl: "40px"
                                        }
                                      }}
                                    >
                                      {job.skills.map((skill) => {
                                        return (
                                          <Grid item key={skill}>
                                            <Chip
                                              label={skill}
                                              color="primary"
                                              sx={{ fontSize: "0.7rem" }}
                                              size="small"
                                            />
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </List>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Link>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: "60vh" }}
                  >
                    <Grid item>
                      <Typography variant="h4" fontWeight="900">
                        You have no jobs
                      </Typography>
                    </Grid>
                  </Grid>
                )
              ) : (
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: "60vh" }}
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {count > 1 ?
            <Grid container item direction="row"
              justifyContent={{
                lg: "center",
                md: "center",
                sm: "center",
                xs: "center",
              }} >
              <Grid item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container justifyContent={"center"}
                /* sx={{
                  marginBottom: "15px",
                }} */>
                {!callback ?
                  <Stack>
                    <Pagination
                      count={count}
                      page={pageNumber}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      style={{
                        transform: "scale(0.8)",
                      }}
                    />
                  </Stack> : <p>Loading...</p>}
              </Grid>
            </Grid> : null}
        </Grid>
      </Grid>
    </>
  );
};

export default JobSearch;
