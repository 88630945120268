import { CLEAR_JOB_FORM_DATA, GET_JOB_FORM_1_DATA, GET_JOB_FORM_2_DATA } from "../actions/types"

const initialState = {
    jobDetails: {
        title: "",
        description: "",
        jobType: "",
        skills: [],
        timeAvailability: "",
        status: "",
        units: "",
        request: [""],
        responsibilities: [],
        requirements: [],
        qualifications: [],
        projectID: ""
    }
};

export const jobReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_JOB_FORM_1_DATA: return {
            ...state,
            jobDetails: {
                ...state.jobDetails,
                title: action.payload.title,
                jobType: action.payload.jobType,
                timeAvailability: action.payload.timeAvailability,
                units: action.payload.units,
                description: action.payload.description
            }

        }

        case GET_JOB_FORM_2_DATA: return {
            ...state,
            jobDetails: {
                ...state.jobDetails,
                responsibilities: action.payload.responsibilities,
                requirements: action.payload.requirements,
                qualifications: action.payload.qualifications,
                projectID: action.payload.projectID
            }
        }

        case CLEAR_JOB_FORM_DATA: return {
            ...state,
            jobDetails: {
                ...state.jobDetails,
                title: "",
                description: "",
                jobType: "",
                skills: [],
                timeAvailability: "",
                status: "",
                units: "",
                request: [""],
                responsibilities: [],
                requirements: [],
                qualifications: [],
                projectID: ""
            }
        }
    }

    return state
}