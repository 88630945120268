import React from "react";

function AgreementStatement({ fillDate, partyName }) {

  // date format for Agreement

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  return (
    <div id="divToPrint">
      <p>
        This Mutual Nondisclosure Agreement (this “Agreement”),
        effective {convert(fillDate)}, is entered into by and between TinT a Delaware Limited Liability Company (“Company”) and
        “{partyName}” (each herein referred to individually as a “Party,” or collectively as the “Parties”).
        In consideration of the covenants and conditions contained herein, the Parties hereby agree to the following:
      </p>
      <br></br>
      <p>
        1.Purpose<br></br>
        The Parties wish to explore a business opportunity of mutual (the “Opportunity”),
        and in connection with the Opportunity, each Party has disclosed,
        and may further disclose certain confidential technical and business information
        (in such capacity, a Party disclosing the information, the “Discloser”) to the other Party
        (in such capacity, a Party receiving the information, the “Recipient”),
        that Discloser desires Recipient to treat as confidential.
      </p>
      <br></br>
      <p>
        2.Confidential Information<br></br>
        A.Definition.<br></br>
        “Confidential Information” means:<br></br>
        (i) any information (including any and all combinations of individual items of information) disclosed
        (directly or indirectly) by Discloser to Recipient pursuant to this Agreement that is in written, graphic,
        machine readable or other tangible form (including, without limitation, research, product plans, products,
        services, equipment, customers, markets, software, inventions, discoveries, ideas, processes, designs, drawings,
        formulations, specifications, product configuration information, marketing and finance documents, prototypes,
        samples, data sets, and equipment) and is marked “Confidential,” “Proprietary” or in some other manner to indicate
        its confidential nature;
        (ii) oral information disclosed (directly or indirectly) by Discloser to Recipient
        pursuant to this Agreement; provided that such information is designated as confidential at the time of its
        initial disclosure and reduced to a written summary by Discloser that is marked in a manner to indicate its
        confidential nature and delivered to Recipient within thirty (30) days after its initial disclosure; and
        (iii) information otherwise reasonably expected to be treated in a confidential manner under the circumstances of
        disclosure under this Agreement or by the nature of the information itself. Confidential Information may include
        information of a third party that is in the possession of Discloser and is disclosed to Recipient under this Agreement.
        B.Exceptions.
        Confidential Information shall not, however, include any information that:
        (i) was publicly known or made generally available without a duty of confidentiality prior to the
        time of disclosure by Discloser to Recipient;
        (ii) becomes publicly known or made generally available without a duty of confidentiality after
        disclosure by Discloser to Recipient through no wrongful action or inaction of Recipient;`
        (iii) is in the rightful possession of Recipient without confidentiality obligations at the time
        of disclosure by Discloser to Recipient as shown by Recipient’s then-contemporaneous written files
        and records kept in the ordinary course of business;
        (iv) is obtained by Recipient from a third party without an accompanying duty of confidentiality and
        without a breach of such third party’s obligations of confidentiality; or
        (v) is independently developed by Recipient without use of or reference to Discloser’s Confidential
        Information, as shown by written records and other competent evidence prepared contemporaneously with
        such independent development; provided that any combination of individual items of information shall
        not be deemed to be within any of the foregoing exceptions merely because one or more of the individual
        items are within such exception, unless the combination as a whole is within such exception.

        C.Compelled Disclosure.<br></br>
        If Recipient becomes legally compelled to disclose any Confidential Information,
        other than pursuant to a confidentiality agreement, Recipient will provide Discloser prompt written notice,
        if legally permissible, and will use its best efforts to assist Discloser in seeking a protective order or
        another appropriate remedy. If Discloser waives Recipient’s compliance with this Agreement or fails to obtain
        a protective order or other appropriate remedy, Recipient will furnish only that portion of the Confidential
        Information that is legally required to be disclosed; provided that any Confidential Information so disclosed
        shall maintain its confidentiality protection for all purposes other than such legally compelled disclosure.
      </p>
      <br></br>
      <p>
        3.Non-use and Non-disclosure<br></br>
        Recipient shall not use any Confidential Information of Discloser for any purpose except to evaluate and
        engage in discussions concerning the Opportunity. Recipient shall not disclose any Confidential Information
        of Discloser to third parties or to Recipient’s employees, except that, subject to Section 4 below, Recipient
        may disclose Discloser’s Confidential Information to those employees of Recipient who are required to have such
        information in order to evaluate or engage in discussions concerning the Opportunity. Recipient shall not
        reverse engineer, disassemble, or decompile any prototypes, software, samples, or other tangible objects that
        embody Discloser’s Confidential Information and that are provided to Recipient under this Agreement.
      </p>
      <br></br>
      <p>
        4.Maintenance of Confidentiality<br></br>
        Recipient shall take reasonable measures to protect the secrecy of and avoid disclosure and unauthorized use of
        the Confidential Information of Discloser. Without limiting the foregoing, Recipient shall take at least those
        measures that it employs to protect its own confidential information of a similar nature and shall ensure that
        its employees who have access to Confidential Information of Discloser have signed a non-use and non-disclosure
        agreement in content at least as protective of Discloser and its Confidential Information as the provisions of this
        Agreement, prior to any disclosure of Discloser’s Confidential Information to such employees.
        Recipient shall reproduce Discloser’s proprietary rights notices on any such authorized copies in the same manner
        in which such notices were set forth in or on the original. Recipient shall promptly notify Discloser of any
        unauthorized use or disclosure, or suspected unauthorized use or disclosure, of Discloser’s Confidential Information
        of which Recipient becomes aware.
      </p>
      <br></br>
      <p>
        5.No Obligation<br></br>
        Nothing in this Agreement shall obligate either Party to proceed with any transaction between them,
        and each Party reserves the right, in its sole discretion, to terminate the discussions contemplated by this
        Agreement concerning the Opportunity. Nothing in this Agreement shall be construed to restrict either Party’s
        use or disclosure of its own Confidential Information.
      </p>
      <br></br>
      <p>
        6.No Warranty<br></br>
        ALL CONFIDENTIAL INFORMATION IS PROVIDED “AS IS.” NEITHER PARTY MAKES ANY WARRANTIES, EXPRESS, IMPLIED OR OTHERWISE,
        REGARDING THE ACCURACY, COMPLETENESS OR PERFORMANCE OF ANY CONFIDENTIAL INFORMATION, OR WITH RESPECT TO
        NON-INFRINGEMENT OR OTHER VIOLATION OF ANY INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY OR OF RECIPIENT.
      </p>
      <br></br>
      <p>
        7.Return of Materials<br></br>
        All documents and other tangible objects containing or representing Confidential Information that have been disclosed
        by Discloser to Recipient, and all copies or extracts thereof or notes derived therefrom that are in the possession
        of Recipient, shall be and remain the property of Discloser and shall be promptly returned to Discloser or destroyed
        (with proof of such destruction), each upon Discloser’s written request.
      </p>
      <br></br>
      <p>
        8.No License<br></br>
        Nothing in this Agreement is intended to grant any rights to Recipient under any patent, mask work right or copyright
        of Discloser, nor shall this Agreement grant Recipient any rights in or to the Confidential Information of Discloser
        except as expressly set forth in this Agreement.
      </p>
      <br></br>
      <p>
        9.Export Restrictions<br></br>
        Any software and other technical information disclosed under this Agreement may be subject to restrictions and
        controls imposed by the Export Administration Act, Export Administration Regulations and other laws and regulations
        of the United States and any other applicable government or jurisdiction, as enacted from time to time (the “Acts”).
        The Parties shall comply with all restrictions and controls imposed by the Acts.
      </p>
      <br></br>
      <p>
        10.Term<br></br>
        The obligations of Recipient under this Agreement shall survive, with respect to any particular Confidential
        Information of Discloser, until all Confidential Information of Discloser disclosed hereunder becomes publicly
        known or made generally available through no action or inaction of the Recipient.
      </p>
      <br></br>
      <p>
        11.Remedies<br></br>
        Recipient agrees that any violation or threatened violation of this Agreement may cause irreparable injury to Discloser,
        entitling Discloser to seek injunctive relief in addition to all legal remedies.
      </p>
      <br></br>
      <p>
        12.Miscellaneous<br></br>
        This Agreement shall bind and inure to the benefit of the Parties and their respective successors and permitted assigns.
        Neither Party may assign or otherwise transfer this Agreement without the prior written consent of the other Party;
        except that either Party may assign this Agreement without consent in connection with a merger, reorganization,
        consolidation, change of control, or sale of all or substantially all of the assets to which this Agreement pertains;
        provided that the assigning Party provides prompt written notice to the other Party of any such permitted assignment.
        Any assignment or transfer of this Agreement in violation of the foregoing shall be null and void.
        This Agreement will be interpreted and construed in accordance with the laws of the State of Virginia,
        without regard to conflict of law principles. Each Party hereby represents and warrants that the persons executing
        this Agreement on its behalf have express authority to do so, and, in so doing, to bind such Party thereto.
        This Agreement contains the entire agreement between the Parties with respect to the Opportunity and supersedes
        all prior written and oral agreements between the Parties regarding the Opportunity. Recipient shall not have any
        obligation, express or implied by law, with respect to trade secret or proprietary information of Discloser disclosed
        under this Agreement except as set forth herein. If a court or other body of competent jurisdiction finds any provision
        of this Agreement, or portion thereof, to be invalid or unenforceable, such provision will be enforced to the maximum
        extent permissible to affect the intent of the Parties, and the remainder of this Agreement will continue in full force
        and effect. No provision of this Agreement may be waived except by a writing executed by the Party against whom the
        waiver is to be effective. A Party’s failure to enforce any provision of this Agreement shall neither be construed as
        a waiver of the provision nor prevent the Party from enforcing any other provision of this Agreement.
        No provision of this Agreement may be amended or otherwise modified except by a writing signed by the Parties to this
        Agreement. The Parties may execute this Agreement in counterparts, each of which shall be deemed an original,
        but all of which together constitute one and the same agreement. This Agreement may be delivered by facsimile
        transmission, and facsimile copies of executed signature pages shall be binding as originals.
      </p>
      <br></br>
      <p>
        13.Disputes<br></br>
        All disputes arising out of this Agreement will be subject to the exclusive jurisdiction and venue of the state courts
        and federal courts located in Delaware, and each Party hereby consents to the personal jurisdiction thereof.<br></br><br></br>

        IN WITNESS WHEREOF, the Parties by their duly authorized representatives have executed this Agreement as of the Effective Date.
        <br></br><br></br>
        e-signature statement
      </p>
    </div>
  );
}

export default AgreementStatement;
