import Profile from "./Pages/Profile";
import JobSearch from "./Pages/JobSearch";
import JobDetails from "./Pages/JobDetails";
import Projects from "./Pages/Projects";
import CreateProject1 from "./Pages/CreateProject1";
import CreateProject2 from "./Pages/CreateProject2";
import CreateJob1 from "./Pages/CreateJob1.js";
import CreateJob2 from "./Pages/CreateJob2";
import CreateJob3 from "./Pages/CreateJob3";
import ProjectDetails from "./Pages/ProjectDetails";
import EquityDetails from "./Pages/EquityDetails";
import Skills from "./Pages/Skills";
import ApprovalRequestPage from "./Pages/approvalRequest";
import JobApplicantList from "./Pages/JobApplicantList";
import NdaAcknowledgement from "./Pages/JobDetailsModels/NDAModels/NdaAcknowledgement";
import NdaStep1 from "./Pages/JobDetailsModels/NDAModels/NdaStep1";
import NdaStep2 from "./Pages/JobDetailsModels/NDAModels/NdaStep2";
import Nda from "./Pages/JobDetailsModels/NDAModels/Nda";
import JobAgreementModal from "./Pages/JobAgreementModel/JobAgreementModal";


export const routes = [

  {
    name: "Profile",
    element: <Profile />,
    path: "/profile/:userId",
  },
  {
    name: "JobSearch",
    element: <JobSearch />,
    path: "/jobSearch",
  },

  {
    name: "JobDetails",
    element: <JobDetails />,
    path: "/jobDetails/:jobId",
  },

  {
    name: "projects",
    element: <Projects />,
    path: "/projects",
  },

  {
    name: "ProjectDetails",
    element: <ProjectDetails />,
    path: "/projectdetails/:projId",
  },
  {
    name: "jobapplicantlist",
    element: <JobApplicantList />,
    path: "/jobapplicantlist/:jobId",
  },

  {
    name: "equitydetails",
    element: <EquityDetails />,
    path: "/equitydetails/:projId",
  },

  {
    name: "Skills",
    element: <Skills />,
    path: "/Skills",
  },
  {
    name: "createproject1",
    element: <CreateProject1 />,
    path: "/createproject1",
  },
  {
    name: "createproject2",
    element: <CreateProject2 />,
    path: "/createproject2",
  },
  {
    name: "createjob1",
    element: <CreateJob1 />,
    path: "/createjob1",
  },
  {
    name: "createjob2",
    element: <CreateJob2 />,
    path: "/createjob2",
  },
  {
    name: "createjob3",
    element: <CreateJob3 />,
    path: "/createjob3",
  },
  {
    name: "approvalRequest",
    element: <ApprovalRequestPage />,
    path: "/approvalRequest",
  },
  {
    name: 'nda',
    element: <Nda />,
    path: "/nda"
  },
  {
    name: 'ndaAcknowledgement',
    element: <NdaAcknowledgement />,
    path: "/ndaAcknowledgement/:ndaId"

  },
  {
    name: 'NdaStep1',
    element: <NdaStep1 />,
    path: "/NdaStep1/:ndaId"
  },
  {
    name: 'NdaStep2',
    element: <NdaStep2 />,
    path: "/NdaStep2/:ndaId"

  },
  {
    name: 'JobAgreementModal',
    element: <JobAgreementModal />,
    path: "/jobAgreement/:applicationId"

  },

];
