import { CLEAR_JOB_FORM_DATA, GET_JOB_FORM_1_DATA, GET_JOB_FORM_2_DATA } from "./types"

export const getForm1Data = (data) => {
    return {
        type: GET_JOB_FORM_1_DATA,
        payload: data
    }
}

export const getForm2Data = (data) => {
    return {
        type: GET_JOB_FORM_2_DATA,
        payload: data
    }
}

export const clearFormData = () => {
    return {
        type: CLEAR_JOB_FORM_DATA,
    }
}