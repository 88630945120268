import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  Snackbar,
  Alert,
  MenuItem
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../axios";

const year = new Date().getFullYear();
const url = process.env.REACT_APP_BE_URL;

let EducationSchema = Yup.object().shape({
  universityOrSchool: Yup.string()
    .required(" University or School is required")
    .min(3, "Too short")
    .max(60, "Too long"),
  degree: Yup.string()
    .min(3, "Too short")
    .max(45, "Too long"),
  fieldOfStudy: Yup.string()
    .required(" Field of Study is required")
    .min(3, "Too short")
    .max(45, "Too long"),

  grade: Yup.string()
    .min(1, "Too short")
    .max(20, "Too long")
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const fieldStyles = {
  width: { xs: 298, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 30,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
  border: "0px",
};

function Education({
  open,
  onClose,
  isEditData,
  setIndex,
  index,
  setIsEditData,
  data,
  getData,
}) {
  const [initialValues, setInitialValues] = useState({
    universityOrSchool: "",
    fieldOfStudy: "",
    grade: "",
    degree: ""
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  // eslint-disable-next-line
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [startDateArray, setStartDateArray] = useState([]);
  const [endDateArray, setEndDateArray] = useState([]);
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const handleChange = (event) => {
    endYearRange(event.target.value)
    setStartYear(event.target.value)
  };

  const handleChangeEnd = (event) => {
    setEndYear(event.target.value)
  };


  useEffect(() => {
    if (isEditData) {
      setInitialValues({
        universityOrSchool: data?.education[index]?.universityOrSchool,
        fieldOfStudy: data?.education[index]?.fieldOfStudy,
        grade: data?.education[index]?.grade,
        degree: data?.education[index]?.degree,
      });
      setStartYear(data?.education[index]?.startYear)
      setEndYear(data?.education[index]?.endYear)
    } else {
      setInitialValues({
        universityOrSchool: "",
        fieldOfStudy: "",
        grade: "",
        degree: ""
      });
      setStartYear('')
      setEndYear('')
    }
    startYearRange()
  }, [data, index, isEditData]);

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const items = JSON.parse(localStorage.getItem("userInfo"));

  const handleSubmit = async (values, { resetForm }) => {
    if (startYear <= endYear || endYear === "") {
      const newValues = { ...values, startYear: startYear, endYear: endYear }
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };

      try {
        let payLoad;
        if (!isEditData) {
          payLoad = {
            education: [...data.education, newValues],
          };
        } else {
          data.education[index] = newValues;
          payLoad = {
            education: data.education,
          };
        }

        const { status } = await axios.put(
          `${url}/user/${items._id}`,
          payLoad,
          config
        );
        resetForm();
        onClose();
        setIsEditData(false);
        getData();

        if (status === 200 && isEditData) {
          setAlert({
            showAlert: true,
            severity: "success",
            message: "Education details are updated successfully!",
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: "success",
              message: "",
            });
          }, 16000);
        } else if (status === 200 && !isEditData) {
          setAlert({
            showAlert: true,
            severity: "success",
            message: "Education details are added successfully!",
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: "success",
              message: "",
            });
          }, 16000);
        }
      } catch (error) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    }
  };

  const startYearRange = () => {
    let newarray = []
    let currentYear = new Date().getFullYear()
    for (let i = 0; i < 50; i++) {
      newarray.push(currentYear - i)
      if (i === 49) {
        setStartDateArray([...newarray])
        if (data?.education[index]?.startYear) {
          const spliteArray = newarray.filter((item) => item >= data?.education[index]?.startYear)
          setEndDateArray([...spliteArray])
        } else {
          setEndDateArray([...newarray])
        }
      }
    }
  }

  const endYearRange = (startYear) => {
    const spliteArray = startDateArray.filter((item) => item >= startYear)
    setEndDateArray([...spliteArray])
  }

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid item style={TitleStyles}>
              Education
            </Grid>
            <Grid container item direction="column" alignItems="center">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={EducationSchema}
              >
                {({ errors, isValid, values, touched, dirty }) => (
                  <Form style={formStyles}>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="universityOrSchool"
                        label="University, School or Institution  "
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        error={
                          Boolean(errors.universityOrSchool) &&
                          Boolean(touched.universityOrSchool)
                        }
                        helperText={
                          Boolean(touched.universityOrSchool) &&
                          errors.universityOrSchool
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="degree"
                        label="Degree"
                        as={TextField}
                        variant="outlined"
                        size="small"
                        margin="dense"
                        error={
                          Boolean(errors.degree) &&
                          Boolean(touched.degree)
                        }
                        helperText={
                          Boolean(touched.degree) && errors.degree
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="fieldOfStudy"
                        label="Field of Study"
                        as={TextField}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        error={
                          Boolean(errors.fieldOfStudy) &&
                          Boolean(touched.fieldOfStudy)
                        }
                        helperText={
                          Boolean(touched.fieldOfStudy) && errors.fieldOfStudy
                        }
                      ></Field>
                    </Grid>

                    <Grid item>
                      <TextField
                        sx={fieldStyles}
                        select
                        name="startYear"
                        label="Start Year"
                        value={startYear}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        size="small"
                        margin="dense"
                        helperText="Please select a start year"
                      >
                        {startDateArray?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        sx={fieldStyles}
                        select
                        name="endYear"
                        label="End Year( Not required if still studying )"
                        value={endYear}
                        onChange={handleChangeEnd}
                        variant="outlined"
                        size="small"
                        margin="dense"
                        helperText="Please select end year"
                      >
                        {endDateArray?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="grade"
                        label="Grade Point Average(GPA)"
                        as={TextField}
                        variant="outlined"
                        type="industry"
                        size="small"
                        margin="dense"
                        error={Boolean(errors.grade) && Boolean(touched.grade)}
                        helperText={Boolean(touched.grade) && errors.grade}
                      ></Field>
                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          height: "2.5rem",
                          marginTop: "1rem",
                        }}
                        color="primary"
                        fullWidth
                        variant="contained"
                        disabled={!isValid && startYear}
                        type="submit"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default Education;
