import {
  Box,
  Button,
  Grid,
  Typography,
  Alert,
  Snackbar,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import Chip from "@mui/material/Chip";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";

import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

import { useParams, Link } from "react-router-dom";
import axiosInstance from "../axios";
import CircularProgress from "@mui/material/CircularProgress";
import CreateIcon from "@mui/icons-material/Create";
import RequiredSkillsmodel from "./JobDetailsModels/RequiredSkillsmodel";
import JobRequirementmodel from "./JobDetailsModels/JobRequirementmodel";
import JobResponsibilitiesmodel from "./JobDetailsModels/JobResponsibilitiesmodel";
import JobQualificationsmodel from "./JobDetailsModels/JobQualificationsmodel";
import JobdetailsMainmodel from "./JobDetailsModels/JobdetailsMainmodel";

const url = process.env.REACT_APP_BE_URL;

const listStyle = {
  marginBottom: {
    lg: "0.5rem",
    md: "0.5rem",
    sm: "0.5rem",
    xs: "1rem",
  },
};

const SubTitleHeaders = {
  textAlign: "center",
  fontWeight: "700",
  fontSize: {
    lg: "1rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
};

const JobDetails = () => {

  const [modelOpen, setmodelOpen] = useState(true);
  const [usercanEdit, setUsercanEdit] = useState(false);
  const [inAdminsArry, setinAdminsArry] = useState(false);

  const UserId = JSON.parse(localStorage.getItem("userInfo"));

  const [gridChanage, setgridChanage] = useState(false);

  // eslint-disable-next-line
  const [open, setOpen] = useState(false);;

  function handleClose() {
    setOpen(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [btnDisabled, setbtnDisabled] = useState(false);
  const [interestDisabled, setInterestDisabled] = useState(false);

  const [projectData, setprojectData] = useState();
  const [appliedJobs, setAppliedJobs] = useState(null);

  const { jobId } = useParams();

  const [jobData, setjobData] = useState();
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
        }`,
    },
  };

  const handleSubmit = async () => {
    try {
      const payload = { status: jobData?.status === "inactive" ? "active" : "inactive" }
      const res = await axiosInstance.put(`${url}/job/${jobId}`, payload, config);
      if (res.status === 200) {
        getData()
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Job Details updated!"}`,
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const getData = async () => {
    try {
      const { data } = await axiosInstance.get(`${url}/job/${jobId}`, config);

      setjobData(data);
      setprojectData(data?.projectID);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  //Delete Button Functions for Required Skills
  const deleteRequiredskills = async (item) => {
    let newSkills = jobData.skills.filter((skill) => skill !== item);

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let skillsPayLoad = {
        skills: newSkills,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        skillsPayLoad,
        config
      );


      if (res.status === 200) {
        getData();
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully"}`,
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  //Delete Button Functions for job requirements
  const deleteJobRequirements = async (requirements) => {
    let newJobrequirements = jobData.requirements.filter(
      (requirement) => requirement !== requirements
    );
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let jobRequirementspayLoad = {
        requirements: newJobrequirements,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        jobRequirementspayLoad,
        config
      );


      if (res.status === 200) {
        getData();
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully"}`,
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  //Delete Button Functions for job requirements
  const deleteJobResponsibilities = async (responsibilities) => {
    let newJobresponsibilities = jobData.responsibilities.filter(
      (responsibility) => responsibility !== responsibilities
    );
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let jobResponsibilitiespayLoad = {
        responsibilities: newJobresponsibilities,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        jobResponsibilitiespayLoad,
        config
      );

      if (res.status === 200) {
        getData();
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully"}`,
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  //Delete Button Functions for job requirements
  const deleteJobQualifications = async (qualifications) => {
    let newJobqualifications = jobData.qualifications.filter(
      (qualifi) => qualifi !== qualifications
    );
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let jobQualificationspayLoad = {
        qualifications: newJobqualifications,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        jobQualificationspayLoad,
        config
      );


      if (res.status === 200) {
        getData();
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully"}`,
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const checkUserData = () => {

    if (!projectData?.admins?.find((admin) => admin === UserId?._id)) {
      setUsercanEdit(false);

    } else {
      setUsercanEdit(true);
    }

    if (projectData?.admins?.find((admin) => admin === UserId._id)) {
      setinAdminsArry(true);
    }

    if (usercanEdit) {
      setgridChanage(8);
    } else if (!usercanEdit) {
      setgridChanage(8);
    } else {
      setgridChanage(8);
    }
  };

  //model close
  const handleCloseModelrSkill = () => {
    setOpenModelrSkill(false);
  };

  const handleCloseModelrJobReq = () => {
    setOpenModelJobReq(false);
  };

  const handleCloseModelrJobRes = () => {
    setOpenModelJobRes(false);
  };

  const handleCloseModelrJobQualifi = () => {
    setOpenModelJobQualifi(false);
  };

  const handleCloseModelmaindetails = () => {
    setopenModelmaindetails(false);
  };

  //models states
  const [openModelrSkill, setOpenModelrSkill] = useState(false);
  const [OpenModelJobReq, setOpenModelJobReq] = useState(false);
  const [OpenModelJobRes, setOpenModelJobRes] = useState(false);
  const [OpenModelJobQualifi, setOpenModelJobQualifi] = useState(false);
  const [openModelmaindetails, setopenModelmaindetails] = useState(false);


  const putJob = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    setbtnDisabled(true);

    let applicationPayload = {
      userID: `${UserId._id}`,
      jobID: `${jobId}`,
    };

    try {
      const { status, data } = await axiosInstance.post(
        `${url}/application`,
        applicationPayload,
        config
      );
      if (status === 200) {
        setbtnDisabled(false);
        getJob();

        setAlert({
          showAlert: true,
          severity: "success",
          message: "Successfully applied!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setbtnDisabled(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };


  const getJob = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };


    try {
      const res = await axiosInstance.get(
        `${url}/application/${UserId._id}`,
        config
      );

      if ((res.status == 200)) {
        setAppliedJobs(res);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };





  const findAlradyIn = () => {

    if (appliedJobs.data.find((applications) => applications.jobID === jobId)) {
      setInterestDisabled(true);
    } else {
      setInterestDisabled(false);
    }
  };

  useEffect(() => {
    getData();
    getJob();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectData) {
      checkUserData();
    }
    // eslint-disable-next-line
  }, [projectData]);

  useEffect(() => {
    if (appliedJobs) {
      findAlradyIn();
    }
    // eslint-disable-next-line
  }, [appliedJobs]);

  return (
    <>
      <Navbar />

      <Grid container lg={12}>
        <Grid container item lg={1} md={1.5} sm={1.8} xs={1}>
          <Sidebar />
        </Grid>

        <Grid container item marginTop={10} lg={10.5} md={9.8} sm={10} xs={10}>
          {!jobData || !projectData ? (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              style={{ height: "100vh" }}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid container sx={{ paddingTop: "2rem" }}>
              <Snackbar
                open={alert.showAlert}
                autoHideDuration={15000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert
                  severity={alert.severity}
                  onClose={handleClose}
                  sx={{
                    fontSize: "16px",
                    overflowWrap: "break-word",
                    marginTop: "4rem",
                    marginRight: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "1rem",
                    },
                  }}
                >
                  {alert.message}
                </Alert>
              </Snackbar>

              <Grid
                container
                item
                spacing={1}
                alignItems="center"
                justifyContent="center"
                sx={{
                  paddingLeft: {
                    lg: "2rem",
                    md: "2rem",
                    sm: "4rem",
                    xs: "1rem",
                  },
                  paddingRight: {
                    lg: "2rem",
                    md: "2rem",
                    sm: "4rem",
                    xs: "1rem",
                  },
                }}
              >
                <Grid item lg={gridChanage} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "900",
                      textAlign: {
                        lg: "left",
                        md: "center",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    {" "}
                    {jobData?.title}
                    {inAdminsArry && (
                      <Chip
                        sx={{
                          marginLeft: "1rem",
                          padding: "15px 10px 15px 10px",
                          fontWeight: 400,
                        }}
                        size="small"
                        label={jobData?.status}
                        color={
                          jobData?.status === "inactive" ? "warning" : "success"
                        }
                        icon={
                          jobData?.status === "inactive" ? (
                            <PriorityHighIcon />
                          ) : (
                            <CheckIcon />
                          )
                        }
                      />
                    )}
                  </Typography>
                </Grid>

                {usercanEdit && (
                  <Grid item lg={2} md={6} sm={12} xs={12}>
                    <Link to={`/jobapplicantlist/${jobId}`}>
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          borderRadius: "8px",
                          textTransform: "none",
                          width: "100%",
                        }}
                      >
                        View Applicant List
                      </Button>
                    </Link>
                  </Grid>)}

                <Grid item lg={2} md={6} sm={12} xs={12}>
                  <Link to={`/projectdetails/${jobData?.projectID?._id}`}>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        borderRadius: "8px",
                        textTransform: "none",
                        width: "100%",
                      }}
                    >
                      Project Details
                    </Button>
                  </Link>
                </Grid>

                {!inAdminsArry && (
                  <Grid item lg={2} md={6} sm={12} xs={12}>
                    {!interestDisabled ? (
                      <LoadingButton
                        disabled={btnDisabled}
                        loading={btnDisabled}
                        color="secondary"
                        variant="contained"
                        sx={{
                          borderRadius: "8px",
                          textTransform: "none",
                          width: "100%",
                        }}
                        onClick={putJob}
                      >
                        {" "}
                        I'm interested
                      </LoadingButton>
                    ) : (
                      <Chip color="success" label="Already applied" variant="contained" sx={{ padding: "18px", width: "100%", }} />
                    )}
                  </Grid>
                )}
              </Grid>

              {/* General JOB Details */}

              <Grid container item alignItems="center" justifyContent="center">
                <JobdetailsMainmodel
                  open={openModelmaindetails}
                  onClose={handleCloseModelmaindetails}
                  data={jobData}
                  data2={projectData}
                  getData={getData}
                  modelOpen={modelOpen}
                  setmodelOpen={setmodelOpen}
                />

                <Box
                  border={1}
                  borderRadius="8px"
                  sx={{
                    width: {
                      lg: "82vw",
                      md: "80vw",
                      sm: "70vw",
                      xs: "75vw",
                    },

                    marginTop: "2rem",
                    marginBottom: "1rem",

                    paddingLeft: {
                      lg: "3rem",
                      md: "3rem",
                      sm: "3rem",
                      xs: "3rem",
                    },
                    paddingRight: {
                      lg: "3rem",
                      md: "3rem",
                      sm: "3rem",
                      xs: "3rem",
                    },
                    paddingTop: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "2rem",
                      xs: "2rem",
                    },
                    paddingBottom: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "2rem",
                      xs: "2rem",
                    },
                  }}
                  marginBottom={4}
                >
                  <Grid
                    container
                    item
                    sx={{
                      textAlign: {
                        lg: "right",
                        md: "right",
                        sm: "right",
                        xs: "right",
                      },
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        marginBottom: {
                          lg: "0rem",
                          md: "1.3rem",
                          sm: "1.3rem",
                          xs: "1.3rem",
                        },
                      }}
                    >
                      {usercanEdit && (
                        <>{jobData?.status === "inactive" ?
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleSubmit();
                            }}
                            sx={{
                              background: "green",
                              fontSize: "0.76rem",
                              borderRadius: "8px",
                              textTransform: "none",
                              marginRight: "0.5rem",
                            }}
                          >
                            Make active
                          </Button>
                          : jobData?.status === "active" ? <Button
                            variant="contained"
                            onClick={() => {
                              handleSubmit();
                            }}
                            sx={{
                              background: "#FF7518",
                              fontSize: "0.76rem",
                              borderRadius: "8px",
                              textTransform: "none",
                              marginRight: "0.5rem",
                            }}
                          >
                            Make inactive
                          </Button> : null}
                          <LoadingButton
                            disabled={!modelOpen}
                            loading={!modelOpen}
                            onClick={() => {
                              setopenModelmaindetails(true);
                            }}
                            variant="outlined"
                            size="small"
                          >
                            <CreateIcon />
                          </LoadingButton>
                        </>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        marginBottom: {
                          lg: "1.3rem",
                          md: "1.3rem",
                          sm: "1.3rem",
                          xs: "1.3rem",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.95rem",
                          wordBreak: "break-word",
                          textAlign: {
                            lg: "left",
                            md: "left",
                            sm: "left",
                            xs: "left",
                          },
                          height: {
                            xs: "10rem",
                            sm: "auto",
                            md: "auto",
                            lg: "auto"
                          },
                          overflow: {
                            xs: "auto",

                          }
                        }}
                      >
                        {jobData?.description}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item sx={listStyle}>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        <ul>
                          <li>Job Title</li>
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        - {jobData?.title}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item sx={listStyle}>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        <ul>
                          <li>Job Type</li>{" "}
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        - {jobData?.jobType}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item sx={listStyle}>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        <ul>
                          <li>Time availability</li>{" "}
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        - {jobData?.timeAvailability}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item sx={listStyle}>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        <ul>
                          <li>Units</li>{" "}
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        - {jobData?.units} (
                        {(
                          (parseInt(jobData?.units) /
                            (parseInt(jobData?.projectID?.totalUnits))) *
                          100
                        ).toFixed(2)}
                        %)
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item sx={listStyle}>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        <ul>
                          <li>Company Name</li>{" "}
                        </ul>{" "}
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        - {projectData?.name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item sx={listStyle}>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        <ul>
                          <li> Industry </li>{" "}
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item lg={10} md={9} sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingLeft: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0rem",
                            xs: "0rem",
                          },
                        }}
                      >
                        - {projectData?.industry}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* Required Skills */}

              <Grid container item>
                {usercanEdit ? (
                  <Grid
                    container
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <RequiredSkillsmodel
                      open={openModelrSkill}
                      onClose={handleCloseModelrSkill}
                      data={jobData}
                      getData={getData}
                    />

                    <Box
                      border={1}
                      borderRadius="8px"
                      sx={{
                        width: {
                          lg: "38vw",
                          md: "38vw",
                          sm: "70vw",
                          xs: "95vw",
                        },

                        marginTop: "2rem",

                        paddingLeft: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "2rem",
                        },
                        paddingRight: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "1rem",
                        },
                        paddingTop: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                        paddingBottom: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                      }}
                      marginBottom={4}
                    >
                      {jobData?.skills?.length > 0 && (
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Required Skills
                            </Typography>
                          </Grid>

                          <Grid item lg={2} md={2} sm={3} xs={4}>
                            <Button
                              onClick={() => {
                                setOpenModelrSkill(true);
                              }}
                              variant="outlined"
                              size="small"
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container item>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <List
                            sx={{
                              height: "150px",
                              overflowX: "hidden",
                              overflowY: "scroll",
                            }}
                          >
                            {jobData?.skills?.length > 0 ? (
                              jobData?.skills.map((skill, i) => {
                                return (
                                  <ListItem
                                    key={i}
                                    disablePadding
                                    disableGutters
                                    alignItems="flex-start"
                                    sx={{
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <ListItemText>
                                      <Typography sx={{ fontSize: "0.875rem" }}>
                                        {skill}
                                      </Typography>
                                    </ListItemText>

                                    <Button
                                      onClick={() =>
                                        deleteRequiredskills(skill)
                                      }
                                      sx={{ marginRight: "36px" }}
                                    >
                                      <DeleteIcon sx={{ color: "#C80815" }} />
                                    </Button>
                                  </ListItem>
                                );
                              })
                            ) : (
                              <Grid
                                container
                                item
                                alignItems="center"
                                direction="column"
                                marginTop="2.5rem"
                              >

                                <Grid item>
                                  <Typography
                                    color="primary"
                                    sx={SubTitleHeaders}
                                  >
                                    Add Your Skills Here
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    onClick={() => {
                                      setOpenModelrSkill(true);
                                    }}
                                    variant="outlined"
                                    size="small"
                                  >
                                    <AddIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  jobData?.skills?.length > 0 && (
                    <Grid
                      container
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        border={1}
                        borderRadius="8px"
                        sx={{
                          width: {
                            lg: "38vw",
                            md: "38vw",
                            sm: "70vw",
                            xs: "95vw",
                          },

                          marginTop: "2rem",

                          paddingLeft: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "2rem",
                          },
                          paddingRight: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "1rem",
                          },
                          paddingTop: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                          paddingBottom: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                        }}
                        marginBottom={4}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Required Skills
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container item>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <List
                              sx={{
                                height: "150px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              {jobData?.skills.map((skill, i) => {
                                return (
                                  <ListItem
                                    key={i}
                                    disablePadding
                                    disableGutters
                                    alignItems="flex-start"
                                    sx={{
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <ListItemText>
                                      <Typography sx={{ fontSize: "0.875rem" }}>
                                        {skill}
                                      </Typography>
                                    </ListItemText>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )
                )}

                {/* Job Requirements*/}

                {usercanEdit ? (
                  <Grid
                    container
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <JobRequirementmodel
                      open={OpenModelJobReq}
                      onClose={handleCloseModelrJobReq}
                      data={jobData}
                      getData={getData}
                    />

                    <Box
                      border={1}
                      borderRadius="8px"
                      sx={{
                        width: {
                          lg: "38vw",
                          md: "38vw",
                          sm: "70vw",
                          xs: "95vw",
                        },

                        marginTop: "2rem",

                        paddingLeft: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "2rem",
                        },
                        paddingRight: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "1rem",
                        },
                        paddingTop: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                        paddingBottom: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                      }}
                      marginBottom={4}
                    >
                      {jobData?.requirements?.length > 0 && (
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Job Requirements
                            </Typography>
                          </Grid>

                          <Grid item lg={2} md={2} sm={3} xs={4}>
                            <Button
                              onClick={() => {
                                setOpenModelJobReq(true);
                              }}
                              variant="outlined"
                              size="small"
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container item>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <List
                            sx={{
                              height: "150px",
                              overflowX: "hidden",
                              overflowY: "scroll",
                            }}
                          >
                            {jobData?.requirements?.length > 0 ? (
                              jobData?.requirements?.map((requirements, i) => {
                                return (
                                  <ListItem
                                    key={i}
                                    disablePadding
                                    disableGutters
                                    alignItems="flex-start"
                                    sx={{
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <ListItemText>
                                      <Typography sx={{ fontSize: "0.875rem" }}>
                                        {requirements}
                                      </Typography>
                                    </ListItemText>

                                    <Button
                                      onClick={() =>
                                        deleteJobRequirements(requirements)
                                      }
                                      sx={{ marginRight: "36px" }}
                                    >
                                      <DeleteIcon sx={{ color: "#C80815" }} />
                                    </Button>
                                  </ListItem>
                                );
                              })
                            ) : (
                              <Grid
                                container
                                item
                                alignItems="center"
                                direction="column"
                                marginTop="2.5rem"
                              >

                                <Grid item>
                                  <Typography
                                    color="primary"
                                    sx={SubTitleHeaders}
                                  >
                                    Add Your Job Requirements Here
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    onClick={() => {
                                      setOpenModelJobReq(true);
                                    }}
                                    variant="outlined"
                                    size="small"
                                  >
                                    <AddIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </List>
                        </Grid>{" "}
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  jobData?.requirements?.length > 0 && (
                    <Grid
                      container
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        border={1}
                        borderRadius="8px"
                        sx={{
                          width: {
                            lg: "38vw",
                            md: "38vw",
                            sm: "70vw",
                            xs: "95vw",
                          },

                          marginTop: "2rem",

                          paddingLeft: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "2rem",
                          },
                          paddingRight: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "1rem",
                          },
                          paddingTop: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                          paddingBottom: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                        }}
                        marginBottom={4}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Job Requirements
                            </Typography>
                          </Grid>

                          <Grid container item>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <List
                                sx={{
                                  height: "150px",
                                  overflowX: "hidden",
                                  overflowY: "scroll",
                                }}
                              >
                                {jobData?.requirements?.map(
                                  (requirements, i) => {
                                    return (
                                      <ListItem
                                        key={i}
                                        disablePadding
                                        disableGutters
                                        alignItems="flex-start"
                                        sx={{
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <ListItemText>
                                          <Typography
                                            sx={{ fontSize: "0.875rem" }}
                                          >
                                            {requirements}
                                          </Typography>
                                        </ListItemText>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </Grid>{" "}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>

              {/* Job Responsibilities */}

              <Grid container item>
                {usercanEdit ? (
                  <Grid
                    container
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <JobResponsibilitiesmodel
                      open={OpenModelJobRes}
                      onClose={handleCloseModelrJobRes}
                      data={jobData}
                      getData={getData}
                    />

                    <Box
                      border={1}
                      borderRadius="8px"
                      sx={{
                        width: {
                          lg: "38vw",
                          md: "38vw",
                          sm: "70vw",
                          xs: "95vw",
                        },

                        marginTop: "2rem",

                        paddingLeft: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "2rem",
                        },
                        paddingRight: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "1rem",
                        },
                        paddingTop: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                        paddingBottom: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                      }}
                      marginBottom={4}
                    >
                      {jobData?.responsibilities?.length > 0 && (
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Job Responsibilities
                            </Typography>
                          </Grid>
                          <Grid item lg={2} md={2} sm={3} xs={4}>
                            <Button
                              onClick={() => {
                                setOpenModelJobRes(true);
                              }}
                              variant="outlined"
                              size="small"
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container item>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <List
                            sx={{
                              height: "150px",
                              overflowX: "hidden",
                              overflowY: "scroll",
                            }}
                          >
                            {jobData?.responsibilities?.length > 0 ? (
                              jobData?.responsibilities.map(
                                (responsibilities, i) => {
                                  return (
                                    <ListItem
                                      key={i}
                                      disablePadding
                                      disableGutters
                                      alignItems="flex-start"
                                      sx={{
                                        marginLeft: "20px",
                                      }}
                                    >
                                      <ListItemText>
                                        <Typography
                                          sx={{ fontSize: "0.875rem" }}
                                        >
                                          {responsibilities}
                                        </Typography>
                                      </ListItemText>

                                      <Button
                                        onClick={() =>
                                          deleteJobResponsibilities(
                                            responsibilities
                                          )
                                        }
                                        sx={{ marginRight: "36px" }}
                                      >
                                        <DeleteIcon sx={{ color: "#C80815" }} />
                                      </Button>
                                    </ListItem>
                                  );
                                }
                              )
                            ) : (
                              <Grid
                                container
                                item
                                alignItems="center"
                                direction="column"
                                marginTop="0.7rem"
                                justifyContent="center"
                              >

                                <Grid item>
                                  <Typography
                                    color="primary"
                                    sx={SubTitleHeaders}
                                  >
                                    Add Your Job Responsibilities <br />Here
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    onClick={() => {
                                      setOpenModelJobRes(true);
                                    }}
                                    variant="outlined"
                                    size="small"
                                  >
                                    <AddIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  jobData?.responsibilities?.length > 0 && (
                    <Grid
                      container
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        border={1}
                        borderRadius="8px"
                        sx={{
                          width: {
                            lg: "38vw",
                            md: "38vw",
                            sm: "70vw",
                            xs: "95vw",
                          },

                          marginTop: "2rem",

                          paddingLeft: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "2rem",
                          },
                          paddingRight: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "1rem",
                          },
                          paddingTop: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                          paddingBottom: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                        }}
                        marginBottom={4}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Job Responsibilities
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container item>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <List
                              sx={{
                                height: "150px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              {jobData?.responsibilities.map(
                                (responsibilities, i) => {
                                  return (
                                    <ListItem
                                      key={i}
                                      disablePadding
                                      disableGutters
                                      alignItems="flex-start"
                                      sx={{
                                        marginLeft: "20px",
                                      }}
                                    >
                                      <ListItemText>
                                        <Typography
                                          sx={{ fontSize: "0.875rem" }}
                                        >
                                          {responsibilities}
                                        </Typography>
                                      </ListItemText>
                                    </ListItem>
                                  );
                                }
                              )}
                            </List>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )
                )}

                {/* Job Qualifications */}

                {usercanEdit ? (
                  <Grid
                    container
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <JobQualificationsmodel
                      open={OpenModelJobQualifi}
                      onClose={handleCloseModelrJobQualifi}
                      data={jobData}
                      getData={getData}
                    />

                    <Box
                      border={1}
                      borderRadius="8px"
                      sx={{
                        width: {
                          lg: "38vw",
                          md: "38vw",
                          sm: "70vw",
                          xs: "95vw",
                        },

                        marginTop: "2rem",

                        paddingLeft: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "2rem",
                        },
                        paddingRight: {
                          lg: "3rem",
                          md: "3rem",
                          sm: "3rem",
                          xs: "1rem",
                        },
                        paddingTop: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                        paddingBottom: {
                          lg: "2rem",
                          md: "2rem",
                          sm: "2rem",
                          xs: "2rem",
                        },
                      }}
                      marginBottom={4}
                    >
                      {jobData?.qualifications?.length > 0 && (
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Qualifications
                            </Typography>
                          </Grid>

                          <Grid item lg={2} md={2} sm={3} xs={4}>
                            <Button
                              onClick={() => {
                                setOpenModelJobQualifi(true);
                              }}
                              variant="outlined"
                              size="small"
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container item>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <List
                            sx={{
                              height: "150px",
                              overflowX: "hidden",
                              overflowY: "scroll",
                            }}
                          >
                            {jobData?.qualifications?.length > 0 ? (
                              jobData?.qualifications?.map(
                                (qualifications, i) => {
                                  return (
                                    <ListItem
                                      key={i}
                                      disablePadding
                                      disableGutters
                                      alignItems="flex-start"
                                      sx={{
                                        marginLeft: "20px",
                                      }}
                                    >
                                      <ListItemText>
                                        <Typography
                                          sx={{ fontSize: "0.875rem" }}
                                        >
                                          {qualifications}
                                        </Typography>
                                      </ListItemText>
                                      <Button
                                        onClick={() =>
                                          deleteJobQualifications(
                                            qualifications
                                          )
                                        }
                                        sx={{ marginRight: "36px" }}
                                      >
                                        <DeleteIcon sx={{ color: "#C80815" }} />
                                      </Button>
                                    </ListItem>
                                  );
                                }
                              )
                            ) : (
                              <Grid
                                container
                                item
                                alignItems="center"
                                direction="column"
                                marginTop="2.5rem"
                              >

                                <Grid item>
                                  <Typography
                                    color="primary"
                                    sx={SubTitleHeaders}
                                  >
                                    Add Your Qualifications Here
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    onClick={() => {
                                      setOpenModelJobQualifi(true);
                                    }}
                                    variant="outlined"
                                    size="small"
                                  >
                                    <AddIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </List>
                        </Grid>{" "}
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  jobData?.qualifications?.length > 0 && (
                    <Grid
                      container
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        border={1}
                        borderRadius="8px"
                        sx={{
                          width: {
                            lg: "38vw",
                            md: "38vw",
                            sm: "70vw",
                            xs: "95vw",
                          },

                          marginTop: "2rem",

                          paddingLeft: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "2rem",
                          },
                          paddingRight: {
                            lg: "3rem",
                            md: "3rem",
                            sm: "3rem",
                            xs: "1rem",
                          },
                          paddingTop: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                          paddingBottom: {
                            lg: "2rem",
                            md: "2rem",
                            sm: "2rem",
                            xs: "2rem",
                          },
                        }}
                        marginBottom={4}
                      >
                        <Grid container item>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={9}
                            xs={8}
                            sx={{
                              marginBottom: {
                                lg: "1.3rem",
                                md: "1.3rem",
                                sm: "1.3rem",
                                xs: "1.3rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "left",
                                xs: "left",
                              },
                            }}
                          >
                            <Typography style={SubTitleHeaders} color="primary">
                              Qualifications
                            </Typography>
                          </Grid>

                          <Grid container item>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <List
                                sx={{
                                  height: "150px",
                                  overflowX: "hidden",
                                  overflowY: "scroll",
                                }}
                              >
                                {jobData?.qualifications?.map(
                                  (qualifications, i) => {
                                    return (
                                      <ListItem
                                        key={i}
                                        disablePadding
                                        disableGutters
                                        alignItems="flex-start"
                                        sx={{
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <ListItemText>
                                          <Typography
                                            sx={{ fontSize: "0.875rem" }}
                                          >
                                            {qualifications}
                                          </Typography>
                                        </ListItemText>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default JobDetails;
