import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../axios";

const url = process.env.REACT_APP_BE_URL;

let JobSchema = Yup.object().shape({
  title: Yup.string()
    // .required("Job Title is required")
    .min(3, "Too short")
    .max(20, "Too long"),
  jobType: Yup.string()
    // .required("Job Type is required")
    .min(3, "Too short")
    .max(20, "Too long"),

  units: Yup.number().required("Units are  required"),

  description: Yup.string()
    // .required("Description is required")
    .min(100, "Too short")
    .max(500, "Too long"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "1rem",
  marginBottom: "1rem",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: 550 },
  bgcolor: "#fff",
  boxShadow: 30,
  borderRadius: "8px",
  padding: "10px 10px 30px 10px",
  border: "0px",
};



const mainModelpayLoad = {
  title: "",
  jobType: "",
  timeAvailability: "",
  units: "",
  description: "",
};

//select formik setup
const CustomizedSelectForFormikForJobType = ({ children, form, field }) => {

  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      required
      label="Job Type"
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

//select formik setup2
const CustomizedSelectForFormikForTimeAvailability = ({ children, form, field }) => {

  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      label="Time Availability"
      required
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

function JobdetailsMainmodel({
  open,
  onClose,
  data,
  data2,
  getData,
  modelOpen,
  setmodelOpen
}) {

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const [initialValues, setInitialValues] = useState({});

  const handleSubmit = async (values, { resetForm }) => {
    setmodelOpen(false);

    mainModelpayLoad.title = values.title;
    mainModelpayLoad.jobType = values.jobType;
    mainModelpayLoad.timeAvailability = values.timeAvailability;
    mainModelpayLoad.units = values.units;
    mainModelpayLoad.description = values.description;
    // mainModelpayLoad2.name = values.name;
    // mainModelpayLoad2.industry = values.industry;

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {

      if (mainModelpayLoad.title !== data.title
        || mainModelpayLoad.jobType !== data.jobType
        || mainModelpayLoad.timeAvailability !== data.timeAvailability
        || mainModelpayLoad.units !== data.units
        || mainModelpayLoad.description !== data.description
      ) {

        const res = await axiosInstance.put(`${url}/job/${data._id}`, mainModelpayLoad, config);


        if (res.status === 200) {
          setAlert({
            showAlert: true,
            severity: "success",
            message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Job Details updated!"}`,
          });
        }
      }

      resetForm();
      onClose();
      getData();
      setInitialValues({
        title: "",
        jobType: "",
        timeAvailability: "",
        units: "",
        description: "",
      });

    } catch (error) {
      resetForm();
      onClose();
      getData();
      setInitialValues({
        title: "",
        jobType: "",
        timeAvailability: "",
        units: "",
        description: "",
      });

      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  useEffect(() => {
    setInitialValues({
      title: data?.title,
      jobType: data?.jobType,
      timeAvailability: data?.timeAvailability,
      units: data?.units,
      description: data?.description,
    })

    if (

      initialValues.title === data?.title
      && initialValues.jobType === data?.jobType
      && initialValues.timeAvailability === data?.timeAvailability
      && initialValues.units === data?.units
      && initialValues.description === data?.description
    ) {
      setmodelOpen(true);
    }
    // eslint-disable-next-line
  }, [data, data2])

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Modal open={modelOpen && open} onClose={onClose}>
        <Box sx={style}>
          <Grid container justifyContent="center">
            <Grid item style={TitleStyles}>
              General Details
            </Grid>
            <Grid container item direction="column" alignItems="center">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={JobSchema}
              >
                {({ errors, isValid, touched, dirty }) => (
                  <Form style={formStyles}>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="title"
                        label="Job Title"
                        as={TextField}
                        variant="outlined"
                        type="text"
                        required
                        size="small"
                        margin="dense"
                        error={Boolean(errors.title) && Boolean(touched.title)}
                        helperText={Boolean(touched.title) && errors.title}
                      ></Field>
                    </Grid>

                    <Grid item mb={2}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth required>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={fieldStyles}
                            name="jobType"
                          >
                            job Type
                          </InputLabel>

                          <Field
                            component={CustomizedSelectForFormikForJobType}
                            name="jobType"
                            as={Select}

                            error={
                              Boolean(errors.jobType) && Boolean(touched.jobType)
                            }
                            helperText={Boolean(touched.jobType) && errors.jobType} >
                            <MenuItem value="Accounting">Accounting</MenuItem>
                            <MenuItem value="Legal">Legal</MenuItem>
                            <MenuItem value="Engineering">Engineering</MenuItem>
                            <MenuItem value="Marketing">Marketing</MenuItem>
                            <MenuItem value="Sales">Sales</MenuItem>
                            <MenuItem value="Management">Management</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Field>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={fieldStyles}
                            name="Time Availability" >
                            Time Availability
                          </InputLabel>
                          <Field
                            as={Select}
                            name="timeAvailability"
                            label="Time Availability"
                            component={CustomizedSelectForFormikForTimeAvailability}
                            required >
                            <MenuItem value="full-time">Full Time</MenuItem>
                            <MenuItem value="part-time">Part Time</MenuItem>
                          </Field>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="units"
                        label="Units"
                        as={TextField}
                        variant="outlined"
                        type="number"
                        required
                        size="small"
                        margin="dense"
                        error={Boolean(errors.units) && Boolean(touched.units)}
                        helperText={Boolean(touched.units) && errors.units}
                      ></Field>
                    </Grid>
                    <Grid item>
                      <Field
                        sx={fieldStyles}
                        name="description"
                        label="Description (Min. 100 characters)"
                        as={TextField}
                        variant="outlined"
                        type="text"
                        required
                        size="small"
                        margin="dense"
                        multiline
                        rows={6}
                        error={
                          Boolean(errors.description) &&
                          Boolean(touched.description)
                        }
                        helperText={
                          Boolean(touched.description) && errors.description
                        }
                      ></Field>
                    </Grid>

                    <Grid item>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          height: "2.5rem",
                          marginTop: "1rem",
                        }}
                        color="primary"
                        fullWidth
                        variant="contained"
                        disabled={!dirty || !isValid}
                        type="submit"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default JobdetailsMainmodel;
