import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Button, IconButton, Stack, styled } from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useNavigate } from "react-router-dom";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import InviteModal from "../Pages/InviteModal";
import axios from "../axios";
import { logout } from "../store/actions/authActions";
import { connect } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import photo from '../assets/TINT_Font White.png'

const url = process.env.REACT_APP_BE_URL;
const Root = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const RootSm = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

function ClippedDrawer(props) {
  const navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("userInfo")).token;

  const [openModel, setOpenModel] = React.useState(false);
  const handleCloseModel = () => {
    setTimeout(() => {
      setOpenModel(false);
    }, 3400);
  };

  const location = window.location.pathname.slice(1);

  const [state, setState] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;

  const id = JSON.parse(localStorage.getItem("userInfo"))._id;
  const logout = async () => {
    try {
      const res = await axios.put(`${url}/user/logout/`, { id: id });
      if (res.status === 200) {
        props.logout(id);
        navigate("/signin");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDrawer = (e) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(e);
  };

  const drawer = () => (
    <Drawer
      open={false}
      variant="permanent"
      sx={{
        width: 80,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 80,
          boxSizing: "border-box",
          background: "#0066B2",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Toolbar />
      <Box>
        <List sx={{ background: "#0066B2" }}>
          {location === "profile" ? (
            <ListItem sx={{ width: 70, background: "#fff" }} disablePadding>
              <ListItemButton
                sx={{ display: "flex", flexDirection: "column" }}
                onClick={() => navigate(`/profile/${id}`)}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <AccountCircleRoundedIcon
                    sx={{ fontSize: 30, color: "#000" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ color: "#000", fontSize: "0.7rem" }}>
                    Profile
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem sx={{ width: 70 }} disablePadding>
              <ListItemButton
                sx={{ display: "flex", flexDirection: "column" }}
                onClick={() => navigate(`/profile/${id}`)}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <AccountCircleRoundedIcon
                    sx={{ fontSize: 30, color: "white" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ color: "#fff", fontSize: "0.7rem" }}>
                    Profile
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}

          <Divider sx={{ background: "white" }} />

          {location === "projects" ? (
            <ListItem sx={{ width: 70, background: "#fff" }} disablePadding>
              <ListItemButton
                sx={{ display: "flex", flexDirection: "column" }}
                onClick={() => navigate("/projects")}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <AssignmentRoundedIcon sx={{ fontSize: 30, color: "#000" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ color: "#000", fontSize: "0.7rem" }}>
                    Projects
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem sx={{ width: 70 }} disablePadding>
              <ListItemButton
                sx={{ display: "flex", flexDirection: "column" }}
                onClick={() => navigate("/projects")}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <AssignmentRoundedIcon
                    sx={{ fontSize: 30, color: "white" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ color: "#fff", fontSize: "0.7rem" }}>
                    Projects
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}

          <Divider sx={{ background: "white" }} />

          {location === "jobsearch" ? (
            <ListItem sx={{ width: 70, background: "#fff" }} disablePadding>
              <ListItemButton
                sx={{ display: "flex", flexDirection: "column" }}
                onClick={() => navigate("/jobsearch")}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <WorkRoundedIcon sx={{ fontSize: 30, color: "#000" }} />
                </ListItemIcon>
                <ListItemText sx={{ justifyContent: "center" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "0.7rem",
                      textAlign: "center",
                    }}
                  >
                    Jobs Search
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem sx={{ width: 70 }} disablePadding>
              <ListItemButton
                sx={{ display: "flex", flexDirection: "column" }}
                onClick={() => navigate("/jobsearch")}
              >
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <WorkRoundedIcon sx={{ fontSize: 30, color: "white" }} />
                </ListItemIcon>
                <ListItemText sx={{ justifyContent: "center" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "0.7rem",
                      textAlign: "center",
                    }}
                  >
                    Jobs Search
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}

          <Divider sx={{ background: "white" }} />
        </List>
      </Box>
    </Drawer>
  );

  const list = () => (
    <Box
      sx={{ width: "top" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {drawer()}
    </Box>
  );

  const menuIcon = () => (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {JSON.parse(localStorage.getItem("userInfo")).userType === "admin" && <MenuItem onClick={() => setOpenModel(true)}>Invite</MenuItem>}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "#0066B2",
        }}
      >
        <Toolbar>
          <RootSm>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              style={{ marginRight: "15px" }}
            >
              {state ? (
                <ArrowBackIcon onClick={toggleDrawer(false)} />
              ) : (
                <MenuIcon onClick={toggleDrawer(true)} />
              )}
              <Drawer open={state} onClose={toggleDrawer(false)}>
                {list()}
              </Drawer>
            </IconButton>
          </RootSm>
          <Typography
            variant="h4"
            noWrap
            component="div"
            fontWeight="800"
            sx={{
              flexGrow: 1,
            }}
          >
            <img src={photo} alt="Error" height={"40"} />
          </Typography>
          <RootSm>{menuIcon()}</RootSm>
          <Root>
            <Stack direction="row" spacing={2} height="2rem">
              {token ? null : (
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate("/signup");
                  }}
                  sx={{
                    ":hover": {
                      bgcolor: "white", // theme.palette.primary.main
                      color: "black",
                    },
                    textTransform: "none",
                  }}
                >
                  Signup
                </Button>
              )}
              <>
                {token ? (
                  <Button
                    color="inherit"
                    onClick={logout}
                    sx={{
                      ":hover": {
                        bgcolor: "white", // theme.palette.primary.main
                        color: "black",
                      },
                      textTransform: "none",
                    }}
                  >
                    Logout
                  </Button>
                ) : (
                  <>
                    <Button
                      color="inherit"
                      onClick={() => {
                        navigate("/signin");
                      }}
                      sx={{
                        ":hover": {
                          bgcolor: "white", // theme.palette.primary.main
                          color: "black",
                        },
                        textTransform: "none",
                      }}
                    >
                      Signin
                    </Button>
                  </>
                )}
                <InviteModal open={openModel} onClose={handleCloseModel} />
                {JSON.parse(localStorage.getItem("userInfo")).userType === "admin" &&
                  <Button
                    onClick={() => setOpenModel(true)}
                    sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      ":hover": {
                        bgcolor: "#000",
                        color: "#fff",
                      },
                      textTransform: "none",
                      ".MuiButtonGroup-grouped:not(:last-of-type):hover": {
                        // class selector
                        borderColor: "red",
                      },
                    }}
                  >
                    Invite
                  </Button>}
                <IconButton onClick={() => navigate(`/profile/${id}`)}>
                  <AccountCircleRoundedIcon
                    sx={{ fontSize: 40, color: "white" }}
                  />
                </IconButton>
              </>
            </Stack>
          </Root>
        </Toolbar>
      </AppBar>
      <Root>{drawer()}</Root>
    </Box>
  );
}

export default connect(null, { logout })(ClippedDrawer);
