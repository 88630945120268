import React, { useState } from "react";
import { Grid, Button, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "../Components/Drawer";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import { getForm2Data } from "../store/actions/jobActions";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.gray,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

let initialValues2 = {
  jobTitle: "",
  jobType: "",
  timeAvalibility: "",
  units: "",
  companyName: "",
  companyLocation: "",
};

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "3rem",
  marginBottom: "1rem",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const SubTitleHeaders = {
  fontWeight: "600",
  fontSize: "1rem",
  marginTop: "2rem",
};
const SubTitleHeaders2 = {
  fontWeight: "600",
  fontSize: "1rem",
  margin: "0rem 2rem 2rem 2rem",
  color: "#999999"

};
const responsibilities = [
  "Phone answering",
  "Customer relations",
  "Product and delivery",
  "complaint response",
  "Regular Attendance",
];
const requirements = [
  "Qualified Bsc Degree",
  "Interpersonal skills.",
  "Qulified Msc Degree",
  "Extended or uncommon working hours",
  "4 year working Experiance",
];
const qualifications = [
  "Teamwork",
  "Multitasking",
  "Decision-making",
  "Self-starting ability",
  "Strategizing",
];

function CreateJob2() {
  const navigate = useNavigate();
  const { state } = useLocation()
  let { id } = state

  const dispatch = useDispatch()
  const data = useSelector(state => state.job.jobDetails)

  const [value1, setValue1] = useState(data.responsibilities ? data.responsibilities : []);
  const [value2, setValue2] = useState(data.requirements ? data.requirements : []);
  const [value3, setValue3] = useState(data.qualifications ? data.qualifications : []);


  const handleSubmit2 = () => {

    let projectID = id;
    let payload = {
      responsibilities: value1,
      requirements: value2,
      qualifications: value3,
      projectID: projectID?.projectID,
    }

    dispatch(getForm2Data(payload))

    navigate("/createjob3", {
      state: {
        id: projectID
      }
    });
  };
  return (
    <>
      <Drawer />
      <Grid container justifyContent="center" sx={{ paddingTop: "40px" }}>
        <Grid item style={TitleStyles}>
          Step 2
        </Grid>
        <Grid container item direction="column" alignItems="center">
          <Typography style={SubTitleHeaders2}>Instructions: Please select an existing option or type a new option and hit enter</Typography>
          <Formik initialValues={initialValues2} onSubmit={handleSubmit2}>

            <Form style={formStyles}>
              <Typography color="primary" style={SubTitleHeaders}>
                Job Responsibilities
              </Typography>
              <Autocomplete
                multiple
                options={responsibilities}
                defaultValue={[responsibilities[0]]}
                renderInput={(params) => (
                  <LightTooltip title="Type and press enter to add custom values" placement="top-start">
                    <TextField
                      sx={fieldStyles}
                      {...params}
                      size="small"
                      variant="outlined"
                    />
                  </LightTooltip>
                )}
                value={value1}
                freeSolo
                onChange={(event, newValue) => setValue1(newValue)}
              />
              <Typography color="primary" style={SubTitleHeaders}>
                Job Requirements{" "}
              </Typography>
              <Autocomplete
                multiple
                options={requirements}
                defaultValue={[requirements[0]]}
                renderInput={(params) => (
                  <LightTooltip title="Type and press enter to add custom values" placement="top-start">
                    <TextField
                      sx={fieldStyles}
                      {...params}
                      size="small"
                      variant="outlined"
                    />
                  </LightTooltip>
                )}
                value={value2}
                freeSolo
                onChange={(event, newValue) => setValue2(newValue)}
              />
              <Typography color="primary" style={SubTitleHeaders}>
                Job Qualifications{" "}
              </Typography>
              <Autocomplete
                multiple
                options={qualifications}
                defaultValue={[qualifications[0]]}
                renderInput={(params) => (
                  <LightTooltip title="Type and press enter to add custom values" placement="top-start">
                    <TextField
                      sx={fieldStyles}
                      {...params}
                      size="small"
                      variant="outlined"
                    />
                  </LightTooltip>
                )}
                value={value3}
                freeSolo
                onChange={(event, newValue) => setValue3(newValue)}
              />
              <Grid item>
                <Button
                  sx={{
                    borderRadius: "8px",
                    fontSize: "1rem",
                    fontWeight: "700",
                    height: "2.5rem",
                    marginTop: "1rem",
                  }}
                  color="primary"
                  fullWidth
                  variant="contained"
                  type="submit"
                  size="large"
                >
                  Continue
                </Button>
              </Grid>
            </Form>

          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateJob2;
