import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from "../axios";

const url = process.env.REACT_APP_BE_URL;

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 350, sm: "55vw" },
  bgcolor: "#fff",
  boxShadow: 30,
  p: 4,
  borderRadius: "8px",
  padding: "30px",
  border: "0px",
};

const topStick = {
  top: 57,
}

const EditEquityTableModel = ({
  open,
  onClose,
  tableDetails,
  getProjData,
  getEquity,
  setChardata,
  getUser,
  setpieData,
  projectData
  , }) => {

  const [initialValues, setInitialValues] = useState([]);

  const [makeEditable, setMakeEditable] = useState(null);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }
  //button functions
  const makeFelidEditable = (id) => {
    setMakeEditable(id)
  }

  //update specific user's units in equality
  const updateUserunits = async (eId, i) => {
    setMakeEditable(false);


    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let userUnitspayLoad = {
        units: initialValues[i].units
      };
      const res = await axiosInstance.put(`${url}/equity/${eId}`, userUnitspayLoad, config);
      getEquity();

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Units updated"}`
        })
        getProjData();
        setpieData();
        getEquity();
        setChardata();
      }
    } catch (error) {

      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
      getEquity();
      setChardata();
      setMakeEditable(false)
    }
  };

  //delete specific user from equality
  const deleteUser = async (eId) => {

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {

      const res = await axiosInstance.delete(`${url}/equity/${eId}`, config);
      getEquity();

      if (res.status === 200) {

        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully"}`
        })
        getProjData();
        setpieData();
        getEquity();
        setChardata();
        setMakeEditable(false)
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
      getEquity();
      setChardata();
      setMakeEditable(false)
    }
  };

  //make user a Admin
  const makeAdmin = async (eId, value) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let userRolepayLoad = {
        role: value
      };
      const res = await axiosInstance.put(`${url}/equity/${eId}`, userRolepayLoad, config);

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Role updated"}`
        });

        setpieData();
        getEquity();
        setChardata();
      }

    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);

      getEquity();
      setChardata();
      setpieData();
    }
  };

  useEffect(() => {
    getEquity();
    getUser();
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (tableDetails) {
      setInitialValues(tableDetails.filter((skill, i) => i !== 0))
    }
  }, [tableDetails])


  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Modal open={open} onClose={onClose}>
        <Box sx={style}>

          <TableContainer
            component={Paper}
            sx={{ maxHeight: "25rem", }}>
            <Table stickyHeader >
              <TableHead>
                <TableRow >
                  <TableCell colSpan={4} align="right">
                    <Typography
                      sx={{
                        textAlign: "right"
                      }}
                    >
                      <span style={{
                        fontWeight: "bold"
                      }}>Unassigned Units</span>: {projectData?.unassignedUnits}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={topStick}
                    align="center">User</TableCell>
                  <TableCell
                    sx={topStick}
                    align="center">Role</TableCell>
                  <TableCell
                    sx={topStick}
                    align="center" >Units</TableCell>
                  <TableCell
                    sx={topStick}
                    align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialValues?.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell align="center">
                      <Typography
                        sx={{
                          textAlign: "center"
                        }}
                      >
                        {row?.firstName} &nbsp; {row?.lastName}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography
                        sx={{
                          textAlign: "center"
                        }}
                      >
                        {row?.role}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        sx={{ minWidth: "90px" }}
                        value={row?.units}
                        disabled={row?._id === makeEditable ? false : true}
                        name="units"
                        onChange={
                          (e) => setInitialValues(oldValue => oldValue.map(mapValue => {

                            if (mapValue._id === row?._id) {
                              return { ...mapValue, units: e.target.value }
                            } else {
                              return { ...mapValue }
                            }
                          }
                          ))} />

                    </TableCell>
                    <TableCell align="center">
                      <Grid container item>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12} >
                          {row?._id !== makeEditable &&
                            <IconButton
                              onClick={() => makeFelidEditable(row?._id)} >
                              <EditIcon
                                color="action"
                              />
                            </ IconButton>}
                          {row?._id === makeEditable &&
                            <IconButton
                              onClick={() => updateUserunits(row?._id, i)} >
                              <DoneIcon
                                color="success"
                              />
                            </ IconButton>}
                          <IconButton>
                            <DeleteIcon
                              onClick={() => deleteUser(row?._id)}
                              color="error"
                            />
                          </ IconButton>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12} >
                          {row?.role !== "admin" && <Button
                            color="primary"
                            variant="contained"
                            style={{
                              borderRadius: "8px",
                              textTransform: "none"
                            }}

                            onClick={() => makeAdmin(row?._id, "admin")}
                          >
                            Make Admin
                          </Button>}
                        </Grid>

                      </Grid>

                    </TableCell>

                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>

        </Box>
      </Modal>

    </>
  );
}

export default EditEquityTableModel;
